import React, { FC, useState } from "react";
import { Form, Modal } from "antd";
import styles from "./styles.module.scss";
import { Col, Row } from "antd";
import { CButton } from "@components/Button";
import { useFeaturedProductLineApi } from "@api/featuredProductLine";
import { IFeaturedProductModal } from "../ts/interfaces";

const DeleteModal: FC<IFeaturedProductModal> = ({ fProductLine, isOpen, onClose }) => {
    const api = useFeaturedProductLineApi();
    const [isButtonDisabled, setButtonDisable] = useState<boolean>(false);
    const [form] = Form.useForm();
    const handleDelete = () => {
        setButtonDisable(true);
        api.deleteFeaturedProductLine(fProductLine!.id);
        form.resetFields();
        setButtonDisable(false);
        onClose();
    };

    return (
        <>
            <Modal visible={isOpen} footer={false} className={styles.modal} onCancel={onClose}>
                <p className={styles.text}>Вы уверены, что хотите удалить фокусную линейку?</p>
                <Form form={form} onFinish={handleDelete}>
                    <Row justify='center' align='middle' style={{ marginTop: "40px" }}>
                        <Col style={{ marginRight: "20px" }}>
                            <CButton
                                label='Вернуться'
                                className={
                                    isButtonDisabled
                                        ? styles.button__cancel__disabled
                                        : styles.button__cancel__active
                                }
                                onClick={onClose}
                                disabled={isButtonDisabled}
                            />
                        </Col>
                        <Col>
                            <CButton
                                label='Удалить'
                                htmlType='submit'
                                className={isButtonDisabled ? styles.button__disabled : styles.button}
                                disabled={isButtonDisabled}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default DeleteModal;
