import React from "react";
import { AdminLayout } from "@components/Layout";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { ProvideAppContext } from "@hooks/useContext";

export const App = () => {
    return (
        <ProvideAppContext>
            <BrowserRouter>
                <AdminLayout />
            </BrowserRouter>
        </ProvideAppContext>
    );
};