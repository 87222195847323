import { Typography } from "antd";
import styles from "./styles.module.scss";
import { DefaulSelect } from "@components/Select";
import { Card, Row, Form, Col } from "antd";
import { IRegion, IUser } from "@pages/Outlet/ts/iterfaces";
import { CButton } from "@components/Button";
import { TypeOutlet } from "@components/TypeOutlet";
import React from "react";
import { useProfile } from "@hooks/useContext";
import { CInput } from "@components/Input";
import { REQUIRED } from "@utils/validation";
import { useState } from "react";
import { useUser } from "@api/user";
import { useEffect } from "react";
import { useOutlet } from "@api/outlet";
import { useRegion } from "@api/region";

export const OutletInfo = () => {
    const [form] = Form.useForm();
    const { getUserList } = useUser();
    const { getRegionList } = useRegion();
    const { user } = useProfile();
    const { createOutlet } = useOutlet();
    const [specialization, setSpecialization] = useState<String>("");
    const [typeOutlet, settypeOutlet] = useState<String>("");
    const [users, setUsers] = useState<IUser[]>([]);
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [isButtonDisable, setDisableButton] = useState<boolean>(false);

    const getTypeId = (regions: { id: number; name: string }[] | null, value: number) => {
        if (regions?.length) {
            for (const type of regions) {
                if (type.id === value) {
                    return type.id;
                }
            }
        }
    };

    const getPersonId = (users: { id: number; fullName: string }[] | null, value: number) => {
        if (users?.length) {
            for (const type of users) {
                if (type.id === value) {
                    return type.id;
                }
            }
        }
    };

    useEffect(() => {
        const getUsersList = async () => {
            const userList = await getUserList({});
            setUsers(userList.filter((e: any) => e.id !== user?.id));
        };
        const getRegionsList = async () => {
            const regionList = await getRegionList();
            setRegions(regionList);
        };

        getRegionsList().catch((e) => console.error(e));
        getUsersList().catch((e) => console.error(e));
    }, [getUserList, getRegionList, user?.id]);

    const selectChangeHandler = (value: any) => form.setFieldsValue({ regionId: getTypeId(regions, value) });
    const selectHandler = (value: any) => form.setFieldsValue({ people: getPersonId(users, value) });
    const typesRegionsOption = React.useMemo(() => {
        return regions?.map((item) => ({ id: item.id, label: item.name }));
    }, [regions]);

    const typesPeopleOption = React.useMemo(() => {
        return users?.map((item) => ({ id: item.id, label: item.fullName }));
    }, [users]);

    const onFinishForm = async (value: any) => {
        setDisableButton(true);
        const user = users.find((item) => item.id === value["userId"]);
        value.user = user;
        const formData = new FormData();
        value["specialization"] = specialization;
        value["type"] = typeOutlet;
        for (let key in value) {
            if (value[key] === undefined || value[key] === "") {
                value[key] = null;
            }
            formData.append(key, value[key]);
        }
        await createOutlet(formData);
        setDisableButton(false);
    };

    return (
        <>
            <Form form={form} onFinish={onFinishForm}>
                <Card className={styles.card}>
                    <Typography.Title style={{ marginBottom: "24px" }} level={3}>
                        Клиент
                    </Typography.Title>

                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <Form.Item name="legalName" rules={[REQUIRED]}>
                                <CInput type="text" name="legalNameInput" label="Юр. наименование" />
                            </Form.Item>
                        </Col>
                        <Col className={styles.form_field}>
                            <Form.Item
                                name="taxNumber"
                                rules={[
                                    { required: true, message: "Поле обязательно" },
                                    {
                                        pattern: new RegExp(/^(\d{10}|\d{12})$/),
                                        message: "Поле должно содержать либо 10 цифр либо 12"
                                    }
                                ]}
                            >
                                <CInput type="text" name="tinInput" label="ИНН" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card className={styles.card}>
                    <Typography.Title style={{ marginBottom: "24px" }} level={3}>
                        Торговая точка
                    </Typography.Title>
                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <Form.Item name="userId" rules={[REQUIRED]}   style={{ marginBottom: "48px" }}>
                                <DefaulSelect
                                    options={typesPeopleOption}
                                    label="Выберите торгового представителя"
                                    onChange={selectHandler}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="name" style={{ marginBottom: "48px" }} rules={[REQUIRED]}>
                        <CInput name="nameInput" label="Название магазина" />
                    </Form.Item>
                    <Form.Item name="address" style={{ marginBottom: "48px" }} rules={[REQUIRED]}>
                        <CInput name="addressInput" label="Адрес" />
                    </Form.Item>
                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <Form.Item name="regionId" rules={[REQUIRED]}>
                                <DefaulSelect
                                    options={typesRegionsOption}
                                    label="Регион"
                                    onChange={selectChangeHandler}
                                />
                            </Form.Item>
                        </Col>
                        <Col className={styles.form_field}>
                            <Form.Item
                                name="city"
                                style={{ marginBottom: "48px" }}
                                rules={[
                                    { required: true, message: "Поле обязательно" },
                                    {
                                        pattern: new RegExp(/^\D*$/),
                                        message: "Поле должно содержать только буквенные символы"
                                    }
                                ]}
                            >
                                <CInput name="cityInput" label="Город" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <Form.Item name="type">
                                <Typography.Title className={styles.typography_title} level={4}>
                                    Тип точки
                                </Typography.Title>
                            </Form.Item>
                        </Col>

                        <Col className={styles.form_field}>
                            <Form.Item name="specialization">
                                <Typography.Title className={styles.typography_title} level={4}>
                                    Специализация
                                </Typography.Title>
                            </Form.Item>
                        </Col>
                    </Row>
                    <TypeOutlet setSpecialization={setSpecialization} setTypeOutlet={settypeOutlet} />
                    <Form.Item name="site" style={{ marginBottom: "48px" }}>
                        <CInput name="siteInput" label="Сайт" className={styles.input_site} />
                    </Form.Item>
                    <Form.Item name="comment" style={{ marginBottom: "48px" }}>
                        <CInput name="commentInput" label="Комментарий" />
                    </Form.Item>
                    <CButton
                        htmlType="submit"
                        label={`Добавить точку`}
                        className={isButtonDisable ? styles.disabled_button : styles.submit_button}
                        disabled={isButtonDisable}
                    />
                </Card>
            </Form>
        </>
    );
};
