import React from "react";
import axios from "@axios";
import { notification } from "antd";
import { useNotification } from "@hooks/UI/useNotification";


export const useUser = () => {
    const { UseDefaultNotification } = useNotification();
    const [loading, setLoading] = React.useState(false);

    const getUserList = React.useCallback(async (filter) => {
        try {
            setLoading(true);
            const res = await axios.get("/user", {
                params: { ...filter }
            });
            return res.data;
        } catch {
            UseDefaultNotification("error", {
                message: "Ошибка",
                description: "Ошибка при загрузке списка пользователей.",
                duration: 5
            });
        } finally {
            setLoading(false);
        }
    }, [UseDefaultNotification]);

    const createUser = React.useCallback(async (user: Object) => {
        try {
            const res = await axios.post("user", user);
            notification.success({ message: res.statusText });
        } catch (e) {
            console.log(e);
        }
    }, []);

    const updateUser = React.useCallback(async (user: Object) => {
        try {
            const res = await axios.put("/user", user);
            notification.success({ message: res.statusText });
        } catch (e) {
            console.log(e);
        }
    }, []);

    return { loading, getUserList, createUser, updateUser };
};