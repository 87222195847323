import React, { useEffect, useMemo, useState } from "react";
import { IOutlet, IRegion, IColumnsName, IUser, IDeleteOneOutlet } from "./ts/iterfaces";
import { useOutlet } from "@api/outlet";
import { Table, Spin } from "antd";
import styles from "./styles.module.scss";
import { OutletColumns } from "@pages/Outlet/components/columns/OutletColumns";
import { useDebouncedCallback } from "use-debounce/lib";
import { OutletHeader } from "@pages/Outlet/components/header/OutletHeader";
import { useUser } from "@api/user";
import { defaultColumnsState } from "@pages/Outlet/utils/constants";
import { getRequestQuery, getValidFilter, getValidSortParam } from "@pages/Outlet/utils/helpers";
import { ColumnsNameType } from "@pages/Outlet/ts/types";
import { useNotification } from "@hooks/UI/useNotification";
import { useRegion } from "@api/region";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { useConfirmationDialog } from "@hooks/UI/useConfirmationDialog";
import { parseResponseAsFile } from "@utils/helpers";
import DeleteOneElemModal from "./components/deleteModal/DeleteOneElementModal";

export const Outlet = () => {
    const [loading, setLoading] = useState(false);
    const { getOutletsList, updateOutletsUser, downloadOutletsReport } = useOutlet();
    const { getUserList } = useUser();
    const { getRegionList } = useRegion();
    const { UseDefaultNotification } = useNotification();
    const [outlets, setOutlets] = useState<IOutlet[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const { deleteOutlet } = useOutlet();
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [filter, setFilter] = useState<IColumnsName>(defaultColumnsState);
    const [deleteElement, setDeleteElement] = useState<React.Key>();
    const [isModalOneElemDelete, setModalOneElemDelete] = useState<boolean>(false);
    const [sort, setSort] = useState<IColumnsName>(defaultColumnsState);
    const [isEditable, setEditable] = useState<boolean>(false);
    const [selectedOutlets, setSelectedOutlets] = useState<React.Key[]>([]);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const [newUser, setNewUser] = useState<IUser | null>(null);
    const requestQuery = useMemo(() => getRequestQuery(filter, sort), [filter, sort]);

    const getOutlets = useDebouncedCallback(async (requestQuery) => {
        const d = await getOutletsList(requestQuery);
        setOutlets(d);
        setLoading(false);
    }, 300);

    const handleEdit = () => {
        setEditable(!isEditable);
        setIsDeleting(false);
    };

    const handleFiltersChange = (
        columnName: ColumnsNameType | string,
        filterValue: FilterValue | string | null
    ): void => {
        try {
            const validFilters: IColumnsName = getValidFilter(filter, columnName, filterValue);
            setFilter(validFilters);
        } catch {
            UseDefaultNotification("error", {
                message: "Ошибка",
                description: `Торговой точки с ИНН ${filterValue} не существует.`,
                duration: 3
            });
        }
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<IOutlet> | SorterResult<any>[]
    ) => {
        applyFilters(filters);
        applySort(sorter);
    };
    const onModalOneElemDelClose = () => {
        setModalOneElemDelete(false);
    };
    const onSendDelete = async (data: IDeleteOneOutlet, setDisableModalButton: (value: boolean) => void) => {
        await deleteOutlet(data);
        getOutlets(requestQuery);
        setModalOneElemDelete(false);
        setDisableModalButton(false);
    };

    const applyFilters = (filters: Record<string, FilterValue | null>) => {
        const filtersArray = Object.entries(filters);
        filtersArray.forEach((filter) => handleFiltersChange(filter[0], filter[1]));
    };

    const applySort = (sorter: any) => {
        const validSortOrder = getValidSortParam(sorter.columnKey, sorter.order);
        setSort({ ...sort, [sorter.columnKey]: validSortOrder });
    };

    useEffect(() => {
        const getUsersList = async () => {
            const d = await getUserList({});
            setUsers(d);
        };

        const getRegionsList = async () => {
            const d = await getRegionList();
            setRegions(d);
        };

        getUsersList().catch((e) => console.error(e));
        getRegionsList().catch((e) => console.error(e));
    }, [getUserList, getRegionList]);

    useEffect(() => {
        setLoading(true);
        getOutlets(requestQuery);
    }, [getOutlets, requestQuery]);

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedOutlets(selectedRowKeys);
        },
        onSelectAll: (selected: boolean) => {
            setSelectedAll(selected);
        }
    };

    const handleConfirmClick = async () => {
        if (newUser) {
            if (selectedAll) {
                const allIds = outlets.map((o) => o.id);
                await updateOutletsUser(allIds, newUser.id);
            } else {
                await updateOutletsUser(selectedOutlets, newUser.id);
            }
            await getOutlets(requestQuery);
            setEditable(false);
        }
    };

    const newUserSelectHandler = (userId: number | string) => {
        const user = users.find((user) => user.id === userId);
        if (selectedOutlets.length && user) {
            setNewUser(user);
            onOpen();
        } else {
            UseDefaultNotification("warning", {
                message: "Внимание!",
                description: user
                    ? "Выберите торговые точки, которые будут переданы пользователю."
                    : "Не удалось найти данного пользователя.",
                duration: 3
            });
        }
    };

    const downloadHandler = async () => {
        const res = await downloadOutletsReport(requestQuery);
        parseResponseAsFile(res, "Список торговых точек");
        UseDefaultNotification("success", {
            message: null,
            description: "Список торговых точек успешно выгружен.",
            duration: 3
        });
    };

    const filterClearHandler = () => {
        setFilter(defaultColumnsState);
    };

    const regionsFilter = useMemo(
        () =>
            regions && regions.length
                ? regions.map((reg) => ({
                      text: reg.name,
                      value: reg.id
                  }))
                : [],
        [regions]
    );

    const usersFilter = useMemo(
        () =>
            users && users.length
                ? users.map((user) => ({
                      text: user.fullName,
                      value: user.id
                  }))
                : [],
        [users]
    );

    const { Dialog, onOpen } = useConfirmationDialog(
        "Сохранение нового пользователя",
        `Вы уверены, что хотите сохранить пользователя ${newUser?.fullName} для выбранных торговых точек?`,
        "Сохранить",
        handleConfirmClick
    );

    return (
        <Spin spinning={loading}>
            <div style={{ padding: "20px 40px" }}>
                <OutletHeader
                    isEditable={isEditable}
                    handleEdit={handleEdit}
                    usersList={users}
                    value={newUser}
                    onSelect={newUserSelectHandler}
                    onDownloadClick={downloadHandler}
                    onFilterClear={filterClearHandler}
                />
                <Table<IOutlet>
                    style={{ marginTop: "30px", maxHeight: "80vh" }}
                    dataSource={
                        outlets ? outlets.map((outlet: IOutlet) => ({ key: outlet.id, ...outlet })) : []
                    }
                    columns={OutletColumns({
                        handleFiltersChange,
                        regionsFilter,
                        usersFilter,
                        filter,
                        setModalOneElemDelete,
                        setDeleteElement
                    })}
                    pagination={{
                        position: ["bottomRight"],
                        pageSize: 50
                    }}
                    rowSelection={
                        isEditable || isDeleting
                            ? {
                                  type: "checkbox",
                                  ...rowSelection
                              }
                            : undefined
                    }
                    scroll={{ y: "75vh" }}
                    rowClassName={styles.row}
                    onChange={handleTableChange}
                />
                <Dialog />
                <DeleteOneElemModal
                    isModalOpen={isModalOneElemDelete}
                    onClose={onModalOneElemDelClose}
                    onSend={onSendDelete}
                    outletId={deleteElement!}
                ></DeleteOneElemModal>
            </div>
        </Spin>
    );
};
