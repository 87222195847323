import styles from "./styles.module.scss";
import React, { FC } from "react";
import clsx from "clsx";
import { TextAreaProps } from "antd/es/input";
import { Input } from "antd";


interface ICustomTextArea {
    name: string;
    label: string;
    rows?: number;
    value?: string;
    cols?: number;
    placeholder?: string;
    onChange?: () => void;
    className?: string;
}

export const CTextArea: FC<ICustomTextArea & TextAreaProps> = (
    {
        name, label, value, placeholder = " ",
        onChange, className, rows = 4, cols = 10, ...rest
    }) => {
    return (
        <div className={styles.inputCont}>
            <Input.TextArea
                name={name}
                rows={rows}
                cols={cols}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={clsx(styles.input, className)}
                {...rest}
            />
            <label htmlFor={name}><span>{label}</span></label>
        </div>
    );
};