import { Table,Spin } from "antd";
import React, {useState} from "react";
import { CSearch } from "@components/Search";
import { CSelect } from "@components/Select";
import { CButton } from "@components/Button";
import { ArrowDownOutlined, ArrowUpOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { parseDate, parseIanaCode } from "@utils/helpers";
import { ColumnsType } from "antd/es/table";
import { useUser } from "@api/user";
import { useDebouncedCallback } from "use-debounce/lib";
import styles from "./styles.module.scss";
import { UserModal } from "./modal/UserModal";
import clsx from "clsx";

export interface IUser {
    id: number;
    name: string;
    email: string;
    firstName: string;
    position: string;
    login: string;
    lastName: string;
    region: IRegion;
    middleName?: string;
    isAdmin?: boolean;
    createdAt: Date;
    timezone: string;
}

export interface IRegion {
    name: string;
}

interface IFilter {
    search: string;
    sort: number;
}

interface IModalState {
    open: boolean;
    data: IUser | undefined;
}

const INITIAL_FILTER: IFilter = {
    search: "",
    sort: 1
};

export const Users = () => {
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = React.useState<IFilter>({ ...INITIAL_FILTER });
    const [data, setData] = React.useState<IUser[]>([]);
    const [modal, setModal] = React.useState<IModalState>({
        open: false,
        data: undefined
    });

    const { getUserList } = useUser();

    const handleEdit = (record: any) => () => {
        setModal({ open: true, data: record });
    };

    const handleChange = (name: string) => (value: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const getData = useDebouncedCallback(async (filter) => {
        try {
            const d = await getUserList(filter);
            setData(d);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    }, 300);

    const handleSelect = (name: string) => (value: string) => {
        if (filter["sort"] === Number(value)) {
            setFilter({ ...filter, sort: Number(filter["sort"] + 2) });
        } else {
            return setFilter({ ...filter, [name]: Number(value) });
        }
    };

    React.useEffect(() => {
        setLoading(true);
        getData(filter);
    }, [getData, filter]);

    const dateOptions = React.useMemo(
        () => [
            {
                id: 1,
                label: "Дате добавления",
                icon:
                    filter.sort === 1 || filter.sort === 3 ? (
                        filter.sort === 1 ? (
                            <ArrowUpOutlined />
                        ) : (
                            <ArrowDownOutlined />
                        )
                    ) : undefined
            },
            {
                id: 2,
                label: "Имени",
                icon:
                    filter.sort === 2 || filter.sort === 4 ? (
                        filter.sort === 2 ? (
                            <ArrowUpOutlined />
                        ) : (
                            <ArrowDownOutlined />
                        )
                    ) : undefined
            }
        ],
        [filter]
    );

    const columns: ColumnsType<IUser> = React.useMemo(
        () => [
            {
                title: "Имя",
                dataIndex: "fullName",
                key: "fullName",
                width: "20%"
            },
            {
                title: "Должность",
                dataIndex: "position",
                key: "position"
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email"
            },
            {
                title: "Регион",
                dataIndex: "region",
                key: "region",
                render: (value: IRegion) => <span>{value ? value.name : "-"}</span>,
                width: "11%"
            },
            {
                title: "Часовой пояс",
                dataIndex: "timezone",
                key: "timezone",
                render: (value: any) => <span>{value ? parseIanaCode(value) : "-"}</span>,
            },
            {
                title: "Администратор",
                dataIndex: "isAdmin",
                key: "isAdmin",
                render: (value: boolean) => (
                    <span className={clsx(value && styles.adminText)}>{value ? "Да" : "Нет"}</span>
                )
            },
            {
                title: "Дата добавления",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (value: any) => <div>{parseDate(value)}</div>
            },
            {
                title: "",
                key: "id",
                dataIndex: "id",
                render: (text: any, record: any) => (
                    <EditOutlined onClick={handleEdit(record)} className={styles.editIcon} />
                ),
                width: "5%"
            }
        ],
        []
    );

    const closeModalHandler = () => {
        setModal({ open: false, data: undefined });
        getData(filter);
    };

    const openModalHandler = () => {
        setModal({ open: true, data: undefined });
    };

    return (
        <Spin spinning={loading} >
        <div style={{ padding: "20px 40px" }}>
            <div className={styles.filterRow}>
                <CSearch value={filter.search} onChange={handleChange("search")} />
                <CSelect options={dateOptions} label='Сортировать по' onSelect={handleSelect("sort")} />
                <CButton onClick={openModalHandler} icon={<PlusOutlined />} label='Добавить' />
            </div>

            <Table<IUser>
                style={{ marginTop: "30px" }}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowClassName={styles.row}
            />
            <UserModal user={modal.data} onClose={closeModalHandler} isOpen={modal.open} />
        </div>
        </Spin>
    );
};
