import { format } from "date-fns";
import FileSaver from "file-saver";
import { TimeZones } from "@pages/User/constants";


export const parseDate = (date: Date): string => {
    return format(new Date(date), "dd-MM-yyyy");
};

export const reduceString = (text: string, max: number, count: number): string => {
    if (text.length > max) {
        return text.slice(0, count) + "...";
    } else {
        return text;
    }
};

export const parseResponseAsFile = (excelBuffer: any, fileName: string, startDate: Date | null = null, endDate: Date | null = null) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const data = new Blob([excelBuffer], { type: fileType });
    const fullFileName = `${fileName} ${getFileDate(startDate, endDate)}`;
    FileSaver.saveAs(data, fullFileName + fileExtension);
};

const getFileDate = (startDate: Date | null, endDate: Date | null) => {
    return startDate && endDate ?
        `от ${format(startDate, "dd.MM.yyyy")} до ${format(endDate, "dd.MM.yyyy")}` :
        `от ${format(new Date(), "dd.MM.yyyy")}`;
}

export const getProp = (object?: any, keys?: any, defaultVal?: any): any => {
    keys = Array.isArray(keys) ? keys : keys.split(".");
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1));
    }
    return object === undefined ? defaultVal : object;
};

const arrToObject = (arr: string[][]) => {
    const obj = {} as { [key: string]: any };
    for (const [key, value] of arr) {
        obj[key] = value;
    }
    return obj;
};

export const stringifyFilterToQuery = (filter: object) => {
    let query = "";
    const queryArray = Object.entries(filter).flat();
    for (let i = 0; i < queryArray.length; i += 2) {
        if (i === 0) {
            query += `?${queryArray[i]}=${queryArray[i + 1]}`;
        } else {
            query += `&${queryArray[i]}=${queryArray[i + 1]}`;
        }
    }
    return query;
};

export const parseFilter = (query: string) => {
    return arrToObject(query.slice(1).split("&").map((item: string) => item.split("=")));
};

export const parseIanaCode = (ianaCode: string) => TimeZones[ianaCode as keyof typeof TimeZones];