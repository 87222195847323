import React, { FC } from "react";
import { Col, Form, Modal, Row } from "antd";
import { CInput } from "@components/Input";
import { CButton } from "@components/Button";
import styles from "./styles.module.scss";
import { useProductLine } from "@api/productLine";
import { CTextArea } from "@components/Textarea";
import { DefaulSelect } from "@components/Select";
import { NOT_REQUIRED_LESS_THAN_255, REQUIRED } from "@utils/validation";
import { IProductLineModal } from "@pages/Product/productInterfaces";


export const ProductLineModal: FC<IProductLineModal> = ({ pLine, isOpen, onClose }) => {

    const [form] = Form.useForm();

    const { createProductLine, updateProductLine } = useProductLine();

    const closeModal = () => {
        onClose();
    };

    form.setFieldsValue({
        status: "Активно"
    });

    React.useEffect(() => {
        if (pLine?.name) {
            form.setFieldsValue({
                ...pLine,
                status: pLine?.status === true ? "Активно" : "Не активно"
            });
        }
        return () => form.resetFields();
    }, [pLine, form]);

    const statusOptions = React.useMemo(() => [
        { id: 1, label: "Активно" },
        { id: 2, label: "Не активно" }
    ], []);

    const handleSubmit = async (value: any) => {
        value.status = value.status === "Активно";
        if (pLine) {
            value.id = pLine.id;
            await updateProductLine(value);
        } else {
            await createProductLine(value);
        }
        form.resetFields();
        form.setFieldsValue({
            status: "Активно"
        });
        closeModal();
    };

    const selectChangeHandler = (value: any) => {
        value === 1 ? form.setFieldsValue({ status: "Активно" }) : form.setFieldsValue({ status: "Не активно" });
    };

    return (
        <Modal
            visible={isOpen}
            onCancel={closeModal}
            footer={false}
            className={styles.modal}
        >
            <Form
                form={form}
                name="signin"
                style={{ width: "100%", padding: "15px 20px" }}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="name"
                    rules={[REQUIRED]}
                >
                    <CInput
                        name="name"
                        label="Название"
                    />
                </Form.Item>

                <Row justify="space-between">
                    <Col>
                        <Form.Item
                            name="manufacturer"
                            rules={[REQUIRED]}
                        >
                            <CInput
                                name="manufacturer"
                                label="Производитель"
                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            name="status"
                        >
                            <DefaulSelect
                                label="Статус"
                                className={styles.select}
                                options={statusOptions}
                                onChange={selectChangeHandler}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="equipmentType"
                    rules={[NOT_REQUIRED_LESS_THAN_255]}
                >
                    <CInput
                        name="equipmentType"
                        label="Тип оборудования"
                    />
                </Form.Item>

                <Form.Item
                    name="description"
                    rules={[REQUIRED]}
                >
                    <CTextArea
                        name="description"
                        rows={6}
                        label="Описание"
                    />
                </Form.Item>

                <CButton
                    label={pLine ? "Изменить" : "Добавить"}
                    htmlType="submit"
                    cClass={styles.button}
                />
            </Form>
        </Modal>
    );
};