export interface IProdLine {
    id: number;
    name: string;
    manufacturer: string;
    status: true | false;
    description: string;
}

export interface IFilter {
    search: string;
    sort: number;
}

export interface IModalState {
    open: boolean;
    data: IProdLine | undefined;
}

export const INITIAL_FILTER: IFilter = {
    search: "",
    sort: 1
};

export interface IProductLineModal {
    pLine?: IProdLine;
    isOpen: true | false;
    onClose: () => void;
}