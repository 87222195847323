import { Input } from "antd";
import React, { FC } from "react";
import clsx from "clsx";
import { InputProps } from "antd/es/input";
import styles from "./styles.module.scss";

interface ICustomInput {
    name: string
    label: string
    value?: string
    type?: "text" | "password" | "textarea" | "file"
    onChange?: React.ChangeEventHandler
    placeholder?: string,
    className?: string | undefined
}

interface ICustomInputFile {
    name: string;
    onChange?: React.ChangeEventHandler;
    className?: string;
    accept?: string;
    label?: string;
}

export const CInput: FC<ICustomInput & InputProps> = (
    { name, label, type = "text", onChange, value, placeholder = " ", className, ...rest }
) => {
    return (
        <div className={styles.inputCont}>
            <Input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={clsx(styles.input, className)}
                {...rest}
            />
            <label htmlFor={name}><span>{label}</span></label>
        </div>
    );
};

export const InputFile: FC<ICustomInputFile> = ({ name, onChange, className, accept, label }) => {
    return (
        <div className={clsx(styles.innput, className)}>
            <label className={styles.ad}>
                {label}
                <Input
                    type="file"
                    name={name}
                    accept={accept}
                    onChange={onChange}
                />
            </label>
        </div>
    );
};