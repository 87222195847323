import React from "react";
import axios from "@axios";
import { notification } from "antd";
import { useNotification } from "@hooks/UI/useNotification";

export const useFeaturedProductLineApi = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const { UseDefaultNotification } = useNotification();
    const updateFeaturedProductLine = React.useCallback(
        async (featuredProductLine: any) => {
            try {
                const res = await axios.put("/featured-product-line", featuredProductLine);
                notification.success({ message: res.statusText });
            } catch (e: any) {
                let text: string;
                const response = e.response?.data;
                if (response && response.status === 400) {
                    text = response.error.message;
                } else if (e.message === "Network Error") {
                    text = "Создать продуктовую линейку не удалось. Возникла ошибка сети";
                }
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: text!,
                    duration: 3
                });
            }
        },
        [UseDefaultNotification]
    );

    const deleteFeaturedProductLine = React.useCallback(
        async (id: number) => {
            try {
                const res = await axios.delete("/featured-product-line", { data: { id: id } });
                notification.success({ message: res.statusText });
            } catch (e: any) {
                let text: string;
                const response = e.response?.data;
                if (response && response.status === 400) {
                    text = response.error.message;
                } else if (e.message === "Network Error") {
                    text = "Удалить фокусную линейку не удалось. Возникла ошибка сети";
                }
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: text!,
                    duration: 3
                });
            }
        },
        [UseDefaultNotification]
    );

    const createFeaturedProductLine = React.useCallback(
        async (featuredProductLine: any) => {
            try {
                const res = await axios.post("/featured-product-line", featuredProductLine);
                notification.success({ message: res.statusText });
            } catch (e: any) {
                let text: string;
                const response = e.response?.data;
                if (response && response.status === 400) {
                    text = response.error.message;
                } else if (e.message === "Network Error") {
                    text = "Создать продуктовую линейку не удалось. Возникла ошибка сети";
                }
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: text!,
                    duration: 3
                });
            }
        },
        [UseDefaultNotification]
    );

    const getFeaturedProductLineList = React.useCallback(
        async (filter) => {
            try {
                setLoading(true);
                const search = filter.search === "" ? null : { ...filter };
                const res = await axios.get("/featured-product-line", {
                    params: { ...search },
                    headers: { Authorization: `${localStorage.token}` }
                });
                return res.data;
            } catch {
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: "Ошибка при загрузке списка продуктовых линеек.",
                    duration: 3
                });
            } finally {
                setLoading(false);
            }
        },
        [UseDefaultNotification]
    );

    return {
        loading,
        createFeaturedProductLine,
        getFeaturedProductLineList,
        updateFeaturedProductLine,
        deleteFeaturedProductLine
    };
};
