import { Col, Pagination, Row,Spin } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect,useState } from "react";
import { parseFilter, stringifyFilterToQuery } from "@utils/helpers";
import { CSelect } from "@components/Select";
import { CSearch } from "@components/Search";
import { CButton } from "@components/Button";
import { useMedia } from "@api/media";
import styles from "./styles.module.scss";
import { IMedia, MediaCard } from "./atoms/Card";
import { useDebouncedCallback } from "use-debounce/lib";
import { useStorage } from "@hooks/useContext";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

interface IFilter {
    search?: string;
    type?: number;
    date?: number;
    page?: number;
}

const INITIAL_FILTER = {
    search: "",
    type: 0,
    date: 1,
    page: 1
};

export const Media = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { getMediaList, deleteMedia, downloadMedia } = useMedia();
    const { mediaTypes, loadMediaTypes } = useStorage();
    const [filter, setFilter] = React.useState<IFilter>({ ...INITIAL_FILTER });
    const [data, setData] = React.useState<IMedia[]>([]);
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const [deleteArr, setDeleteArr] = React.useState<Array<number>>([]);
    const [pagination, setPagination] = React.useState<any>(undefined);

    React.useEffect(() => {
        if (!mediaTypes?.length) {
            loadMediaTypes();
        }
    }, [mediaTypes, loadMediaTypes]);

    const typeOptions = React.useMemo(() => {
        const tmp = [{ id: 0, label: "Все" }];
        mediaTypes?.forEach(item => {
            return tmp.push({ id: item.id, label: item.name });
        });
        return tmp;
    }, [mediaTypes]);

    const dateOptions = React.useMemo(() => [
        {
            id: 1,
            label: "Сначала новые"
        },
        {
            id: 2,
            label: "Сначала старые"
        }
    ], []);

    const getData = useDebouncedCallback(async () => {
        const res: any = await getMediaList(filter);
        setData(res?.data);
        setPagination(res?.pagination);
        setLoading(false);
    }, 200);

    useEffect(() => {
        setLoading(true);
        getData();
    }, [getData, filter]);

    useEffect(() => {
        if (!history.location.search) {
            history.push(stringifyFilterToQuery(INITIAL_FILTER));
        } else {
            setFilter({ ...parseFilter(history.location.search), search: "" });
        }
    }, [history]);

    useEffect(() => {
        history.push(stringifyFilterToQuery(filter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const handleChange = (name: string) => (value: string) => {
        if (name === "type") {
            setFilter({ ...filter, "type": Number(value), "page": 1 });
        } else {
            setFilter({ ...filter, [name]: value });
        }
    };

    const handleChangePagination = (value: number) => {
        setFilter({ ...filter, "page": value });
    };

    const addButtonClick = () => {
        history.push("/media/manage");
    };

    const cardClick = React.useCallback((value: any) => {
        if (isDeleting) {
            if (deleteArr.find(item => item === value)) {
                const tmp = [...deleteArr].filter(item => item !== value);
                setDeleteArr(tmp);
            } else {
                setDeleteArr([...deleteArr, value]);
            }
        } else {
            return null;
        }
    }, [deleteArr, isDeleting]);

    const deleteIconClick = () => {
        setDeleteArr([]);
        setIsDeleting(!isDeleting);
    };

    const handleDelete = async () => {
        const data = {
            id: deleteArr
        };
        await deleteMedia(data);
        setIsDeleting(false);
        getData();
    };

    const cancelDelete = () => {
        setDeleteArr([]);
        setIsDeleting(false);
    };

    const downloadHandler = (value: any) => async () => {
        await downloadMedia(value);
    };

    return (
        <Spin spinning={loading}>
             <div style={{ padding: "20px 40px" }}>        
                 <Row justify="space-between">
                    <Col style={{ display: "flex" }}>
                    <CSelect
                        options={typeOptions}
                        defaultValue={filter.type !== undefined ? typeOptions[filter.type].label : typeOptions[0].label}
                        value={filter.type !== undefined ? typeOptions[filter.type].label : typeOptions[0].label}
                        label="Тип"
                        onChange={handleChange("type")}
                    />

                    <CSelect
                        options={dateOptions}
                        label="По дате"
                        defaultValue={filter.date !== undefined ? dateOptions[filter.date - 1].label : dateOptions[0].label}
                        value={filter.date !== undefined ? dateOptions[filter.date - 1].label : dateOptions[0].label}
                        // value={dateOptions[0].label}
                        onChange={handleChange("date")}
                    />
                </Col>
                <Col>
                    <DeleteOutlined onClick={deleteIconClick} className={styles.trashIcon} />
                    <CButton
                        onClick={addButtonClick}
                        icon={<PlusOutlined />}
                        label="Добавить материалы"
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: "40px", marginBottom: "30px", alignItems: "center" }} justify="space-between">
                <Col>
                    <CSearch
                        value={filter.search}
                        className={styles.searchInput}
                        onChange={handleChange("search")}
                    />
                </Col>
                <Col>
                    <Pagination
                        defaultCurrent={pagination?.page}
                        current={pagination?.page}
                        total={pagination?.total}
                        onChange={handleChangePagination}
                    />
                </Col>
            </Row>
            {isDeleting && (
                <Row justify="end" style={{ margin: "0 0 20px 20px" }}>
                    <CButton cClass={styles.cancelButton} onClick={cancelDelete} label="Отмена" />
                    <CButton disabled={deleteArr?.length === 0}
                             cClass={clsx(styles.deleteButton, deleteArr?.length === 0 && styles.deleteButtonDisabled)}
                             onClick={handleDelete} label="Удалить" />
                </Row>
            )}
            {data.map(o => (
                <MediaCard
                    card={o}
                    onDownload={downloadHandler}
                    active={deleteArr.includes(o.id) ? true : false}
                    hover={isDeleting}
                    key={o.id}
                    onClick={cardClick}
                />
            ))}
            </div>
        </Spin>
    );
};