import clsx from "clsx";
import { Spin, Layout, Menu } from "antd";
import React, { FC, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useProfile } from "@hooks/useContext";
import { SignIn } from "./signIn";
import { Routes } from "@pages/Router";
import {
    UserOutlined,
    FileImageOutlined,
    PicLeftOutlined,
    CloudDownloadOutlined,
    HomeOutlined,
    LogoutOutlined
} from "@ant-design/icons";
import styles from "./styles.module.scss";
import { ApplicationDownload } from "@components/ApplicationDownload";

const { Sider, Content } = Layout;

export const AdminLayout: FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { user, loading } = useProfile();

    const handleMenuItemClick = ({ key }: { key: string }) => {
        key === history?.location.pathname ? history.go(0) : history.push(key);
    };

    const defaultKey = () => {
        return history.location.pathname === "/" ? ["/media"] : [history.location.pathname];
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isActive = useCallback(
        (url: string): boolean => {
            return history.location.pathname === url;
        },
        [history]
    );

    if (location.pathname === "/download/application") {
        return <ApplicationDownload />;
    }

    return (
        <Spin spinning={loading} className={styles.spin}>
            {!user ? (
                <SignIn />
            ) : (
                <Layout style={{ minHeight: "100vh" }}>
                    <Sider className={styles.sider} style={{ backgroundColor: "#FFFFFF" }}>
                        <Menu
                            mode='inline'
                            defaultSelectedKeys={defaultKey()}
                            style={{ height: "100%", borderRight: 0 }}
                            className={styles.menu}
                            onClick={handleMenuItemClick}
                        >
                            <img src='/logo.png' alt='Schneider Electric' />
                            <div className={styles.line} />
                            <Menu.Item
                                key='/media'
                                icon={<FileImageOutlined className={clsx(styles.icon)} />}
                                style={{ marginTop: "20px" }}
                                className={styles.menuItem}
                            >
                                Медиа материалы
                            </Menu.Item>
                            <Menu.Item
                                key='/products'
                                icon={<PicLeftOutlined className={clsx(styles.icon)} />}
                                className={styles.menuItem}
                            >
                                Продуктовые линейки
                            </Menu.Item>
                            <Menu.Item
                                key='/featured-products'
                                icon={<PicLeftOutlined className={clsx(styles.icon)} />}
                                className={styles.menuItem}
                            >
                                Фокусные линейки
                            </Menu.Item>
                            <Menu.Item
                                key='/outlet'
                                icon={<HomeOutlined className={clsx(styles.icon)} />}
                                className={styles.menuItem}
                            >
                                Торговые точки
                            </Menu.Item>
                            <Menu.Item
                                key='/users'
                                icon={<UserOutlined className={clsx(styles.icon)} />}
                                className={styles.menuItem}
                            >
                                Пользователи
                            </Menu.Item>
                            <Menu.Item
                                key='/reports'
                                icon={<CloudDownloadOutlined className={clsx(styles.icon)} />}
                                className={styles.menuItem}
                            >
                                Выгрузка отчетности
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                                icon={<LogoutOutlined className={clsx(styles.icon)} />}
                                className={styles.menuItem}
                                key={"/logout"}
                            >
                                Выйти из приложения
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout>
                        <Content style={{ margin: "0 16px" }}>
                            <Routes />
                        </Content>
                    </Layout>
                </Layout>
            )}
        </Spin>
    );
};
