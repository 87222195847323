import React, { FC } from "react";
import { InputFile } from "@components/Input";
import styles from "./styles.module.scss";
import { useSecret } from "@api/secret";

export const UploadPage: FC = () => {

    const { uploadUsers, uploadOutlets, uploadContacts } = useSecret();

    const uploadUsersHandler = (value: any) => new Promise(() => {
        return new Promise(async (resolve) => {
            const formData = new FormData();
            formData.append("file", value.target.files?.item(0));
            const res = await uploadUsers(formData);
            resolve(res);
        });
    });

    const uploadOutletsHandler = (value: any) => new Promise(() => {
        return new Promise(async (resolve) => {
            const formData = new FormData();
            formData.append("file", value.target.files?.item(0));
            const res = await uploadOutlets(formData);
            resolve(res);
        });
    });
    const uploadContactsHandler = (value: any) => new Promise(() => {
        return new Promise(async (resolve) => {
            const formData = new FormData();
            formData.append("file", value.target.files?.item(0));
            const res = await uploadContacts(formData);
            resolve(res);
        });
    });

    return (
        <div className={styles.container}>
            <InputFile
                className={styles.fileInput}
                name="users"
                onChange={uploadUsersHandler}
                label={"Загрузить пользователей"}
            />
            <InputFile
                className={styles.fileInput}
                name="outlets"
                onChange={uploadOutletsHandler}
                label={"Загрузить торговые точки"}
            />
            <InputFile
                className={styles.fileInput}
                name="contacts"
                onChange={uploadContactsHandler}
                label={"Загрузить контакты"}
            />
        </div>
    );
};