import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Form, Popover, Row, Upload } from "antd";
import { UploadProps } from "antd/es/upload";
import { IMedia } from "./atoms/Card";
import Icon, { CloseOutlined, DeleteOutlined, FileAddOutlined, FileImageOutlined } from "@ant-design/icons";
import { CTextArea } from "@components/Textarea";
import { CInput } from "@components/Input";
import { DefaulSelect } from "@components/Select";
import { CButton } from "@components/Button";
import styles from "./styles.module.scss";
import { ReactComponent as RightArrow } from "@icons/right-arrow.svg";
import { useStorage } from "@hooks/useContext";
import { useMedia } from "@api/media";
import { FILE, NOT_REQUIRED, PREVIEW, REQUIRED } from "@utils/validation";

const { Dragger } = Upload;

const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const getTypeName = (
    data: { id: number; name: string }[] | null,
    value: number
) => {
    if (data?.length) {
        for (const type of data) {
            if (type.id === value) {
                return type.name;
            }
        }
    }
};

const getTypeId = (
    data: { id: number; name: string }[] | null,
    value: string
) => {
    if (data?.length) {
        for (const type of data) {
            if (type.name === value) {
                return type.id;
            }
        }
    }
};

interface IFormMedia extends Omit<IMedia, "preview"> {
    mediaTypeId: number;
    preview: any;
}

export const ManageMedia = () => {
    const location = useLocation<IMedia>();
    const history = useHistory();
    const { loadMediaTypes, mediaTypes } = useStorage();
    const { createMedia, updateMedia } = useMedia();
    const [form] = Form.useForm();
    const [dbPreview, setDbPreview] = React.useState("");
    const [data, setData] = React.useState<IFormMedia | null>(null);
    const [files, setFiles] = React.useState<any>({
        selectedFile: null,
        selectedFileList: []
    });
    const [requiredPriview, setRequiredPreview] = React.useState(false);
    const [imgUrlInBase64, setImgUrlInBase64] = React.useState<string>("");

    let avatarSrc: string;
    if (dbPreview) {
        avatarSrc = location.state.preview;
    } else {
        if (imgUrlInBase64 !== "") {
            avatarSrc = imgUrlInBase64;
        } else {
            avatarSrc = "";
        }
    }

    const normalizingFileUpload = (event: any) => {
        if (Array.isArray(event)) {
            return event;
        }
        return event && event.fileList;
    };

    function getBase64(img: Blob | File): void {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener(
            "load",
            function() {
                setImgUrlInBase64(this.result !== null ? this.result.toString() : "");
            },
            false
        );
    }

    const onChange = (info: any) => {
        const nextState: any = {};

        switch (info.file.status) {
            case "uploading":
                nextState.selectedFile = null;
                nextState.selectedFileList = [info.file];
                break;
            case "done":
                nextState.selectedFile = info.file;
                nextState.selectedFileList = [info.file];
                break;

            default:
                // error or removed
                nextState.selectedFile = null;
                nextState.selectedFileList = [];
        }
        setFiles(nextState);
        form.setFieldsValue({
            file: { fileList: [{ originFileObj: info.file.originFileObj }] }
        });
    };

    const fileDeleteHandler = (name: string) => () => {
        if (name === "file") {
            form.setFieldsValue({ file: null });
            setFiles({
                selectedFile: null,
                selectedFileList: []
            });
        } else {
            form.setFieldsValue({ preview: undefined });
            setImgUrlInBase64("");
            setDbPreview("");
        }
    };

    React.useEffect(() => {
        if (!mediaTypes?.length) {
            loadMediaTypes();
        }
    }, [mediaTypes, loadMediaTypes]);

    const typesOption = React.useMemo(() => {
        return mediaTypes?.map((item) => ({ id: item.id, label: item.name }));
    }, [mediaTypes]);

    const handleSubmit = async (value: any) => {
        value.mediaTypeId = getTypeId(mediaTypes, String(value.mediaTypeId));
        const formData = new FormData();
        for (let key in value) {
            if (key === "preview" || key === "file") {
                if (typeof value[key] === "object") {
                    formData.append(key, value[key].fileList[0].originFileObj);
                } else {
                    if (key === "preview") {
                        formData.append(key, value[key].split("media/")[1]);
                    } else {
                        formData.append(key, value[key]);
                    }
                }
            } else {
                formData.append(key, value[key]);
            }
        }
        if (data?.id) {
            const id = String(data?.id);
            formData.append("id", id);
            await updateMedia(formData);
        } else {
            await createMedia(formData);
        }
        history.push("/media");
    };

    React.useEffect(() => {
        if (location.state) {
            setData({ ...location.state, mediaTypeId: location.state.mediaTypeId });
            setFiles({
                selectedFile: { name: location.state?.file },
                selectedFileList: [{ name: location.state?.file }]
            });
            setDbPreview(location.state.preview);
            setRequiredPreview(true);
        }
        form.setFieldsValue({
            ...location.state,
            mediaTypeId: location.state?.type.name || "Презентации"
        });
    }, [location, form]);

    const selectChangeHandler = (value: any) => {
        form.setFieldsValue({ mediaTypeId: getTypeName(mediaTypes, value) });
    };

    const uploaderProps: UploadProps = {
        name: "preview",
        listType: "picture-card",
        showUploadList: false,
        accept: "image/*",
        beforeUpload: (file: File | Blob) => {
            form.setFieldsValue({
                preview: { fileList: [{ originFileObj: file }] }
            });
            getBase64(file);
            return false;
        }
    };

    const toggleRequiredPreview = (ev: React.MouseEvent<HTMLInputElement>) => {
        ev.stopPropagation();
        setRequiredPreview(!requiredPriview);
    };

    return (
        <div style={{ padding: "20px 40px" }}>
            <Row>
        <span className={styles.location}>
          Медиа материалы <Icon component={RightArrow} />{" "}
            {data ? "Изменение" : "Добавление"}
        </span>
            </Row>
            <Row style={{ marginTop: "36px" }}>
                <Form
                    form={form}
                    name="signin"
                    style={{ width: "100%" }}
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item name="name" rules={[REQUIRED]}>
                                <CInput
                                    name="name"
                                    label="Название"
                                    className={styles.formInput}
                                />
                            </Form.Item>
                            <Form.Item name="description" rules={[REQUIRED]}>
                                <CTextArea
                                    value={data ? data.description : ""}
                                    name="description"
                                    label="Описание"
                                    rows={11}
                                />
                            </Form.Item>
                            <Form.Item name="mediaTypeId">
                                <DefaulSelect
                                    options={typesOption}
                                    label="Тип медиа"
                                    onChange={selectChangeHandler}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={11} offset={1}>
                            <Form.Item name="file" rules={[FILE]}>
                                {files.selectedFile === null && (
                                    <Dragger
                                        fileList={files.selectedFileList}
                                        customRequest={dummyRequest}
                                        onChange={onChange}
                                        className={styles.dragger}
                                    >
                                        {files.selectedFile === null && (
                                            <div className={styles.fileInput}>
                                                <FileAddOutlined className={styles.inputIcon} />
                                                Перетащите или загрузите файл
                                            </div>
                                        )}
                                    </Dragger>
                                )}

                                {files.selectedFile !== null && (
                                    <div className={styles.filePreview}>
                                        <span>{files.selectedFile.name}</span>
                                        <DeleteOutlined
                                            className={styles.fileDeleteIcon}
                                            onClick={fileDeleteHandler("file")}
                                        />
                                    </div>
                                )}
                            </Form.Item>

                            {requiredPriview ? (
                                <Form.Item
                                    name="preview"
                                    rules={requiredPriview ? [PREVIEW] : [NOT_REQUIRED]}
                                    getValueFromEvent={normalizingFileUpload}
                                >
                                    {!avatarSrc && (
                                        <Dragger
                                            {...uploaderProps}
                                            className={styles.dragger}
                                            // onChange={onChange}
                                        >
                                            <div className={styles.previewText}>
                                                <FileImageOutlined className={styles.inputIcon} />
                                                Выберите фотографию для превью
                                            </div>
                                            <Popover content={"Без превью"}>
                                                <CloseOutlined
                                                    onClick={toggleRequiredPreview}
                                                    className={styles.disable_preview}
                                                />
                                            </Popover>
                                        </Dragger>
                                    )}
                                    {avatarSrc && (
                                        <div className={styles.previewImg}>
                                            <div className={styles.upperContainer}>
                                                <DeleteOutlined
                                                    onClick={fileDeleteHandler("preview")}
                                                    className={styles.previewDelete}
                                                />
                                            </div>
                                            <img alt="preview" src={avatarSrc} />
                                        </div>
                                    )}
                                </Form.Item>
                            ) : (
                                <CButton
                                    label="Добавить превью"
                                    onClick={toggleRequiredPreview}
                                />
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <CButton
                            htmlType="submit"
                            label={`${data ? "Изменить" : "Добавить"} медиа материал`}
                            className={styles.formButton}
                        />
                    </Row>
                </Form>
            </Row>
        </div>
    );
};
