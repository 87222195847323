import React, { useState, useEffect, useContext, useCallback, createContext } from "react";
import axios from "@axios";
import { ITypes } from "@hooks/interfaces";

export interface UserProps {
    id: number;
    email?: string;
    hash?: string;
    isAdmin: boolean;
    fullName: string;
    lastName: string;
    firstName: string;
    middleName?: string;
}

export interface ContextProps {
    profile: {
        user: UserProps | null;
        logout: () => void;
        setUser: React.Dispatch<React.SetStateAction<UserProps | null>>;
        loading: boolean;
    };
    storage: {
        mediaTypes: ITypes[];
        loadMediaTypes: () => void;
    };
}

const AppContext = createContext<ContextProps>({
    profile: {
        user: null,
        logout: () => {
            /* empty */
        },
        setUser: () => {
            /* empty */
        },
        loading: true
    },
    storage: {
        mediaTypes: [],
        loadMediaTypes: () => {
            /* empty */
        }
    }
});

export const ProvideAppContext: React.FunctionComponent = ({ children }) => {
    const profile = useProvideProfile();
    const storage = useProvideStorage();
    return <AppContext.Provider value={{ profile, storage }}>{children}</AppContext.Provider>;
};

export const useProfile = () => {
    const { profile } = useContext(AppContext);
    return profile;
};
export const useStorage = () => {
    const { storage } = useContext(AppContext);
    return storage;
};

const useProvideProfile = () => {
    const [user, setUser] = useState<UserProps | null>(null);
    const [loading, setLoading] = useState(true);

    const logout = useCallback(async () => {
        localStorage.setItem("token", "");
        setUser(null);
    }, []);

    const loadUser = useCallback(async () => {
        try {
            const res = await axios.get("user/me");
            setUser(res.data);
        } catch {
            logout();
        }
    }, [logout]);

    const load = useCallback(async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            if (token) {
                await loadUser();
            }
        } catch {
            /* empty */
        } finally {
            setLoading(false);
        }
    }, [loadUser]);

    useEffect(() => {
        load();
    }, [load]);

    return { user, logout, setUser, loading };
};

const useProvideStorage = () => {
    const [mediaTypes, setMediaTypes] = React.useState<ITypes[]>([]);

    const loadMediaTypes = async () => {
        try {
            const res = await axios.get("/media/types");
            setMediaTypes(res.data);
        } catch {
            /* empty */
        }
    };

    return {
        mediaTypes,
        loadMediaTypes
    };
};
