import React, { FC, useState } from "react";
import { Button, Col, Form, Layout, Row, Spin, Typography } from "antd";
import { CInput } from "../Input";
import axios from "@axios";
import { useProfile } from "@hooks/useContext";
import { useNotification } from "@hooks/UI/useNotification";
import styles from "./styles.module.scss";

const { Title } = Typography;

export const SignIn: FC = () => {
    const { setUser } = useProfile();

    const [loading, setLoading] = useState(false);
    const { UseDefaultNotification } = useNotification();

    const handleSubmit = async (values: { email: string; password: string }) => {
        try {
            setLoading(true);
            const res = await axios.post("/signin", values);
            if (res?.data?.user && res?.data?.token) {
                localStorage.setItem("token", res.data.token);
                setUser(res.data.user);
            }
        } catch (e: any) {
            let text: string;
            const response = e.response?.data;
            if (response && response.status === 400) {
                text = response.error.message;
            } else if (response && response.status === 500) {
                text = response.error.message;
            } else if (e.message === "Network Error") {
                text = "Возникла ошибка сети";
            }
            UseDefaultNotification("error", {
                message: "Ошибка",
                description: text!,
                duration: 3,
                placement: "bottomLeft"
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Spin spinning={loading}>
            <Layout style={{ minHeight: "100vh" }}>
                <Row style={{ minHeight: "100vh" }}>
                    <Col className={styles.authLeftSide} span={9}>
                        <Form
                            name='signin'
                            className={styles.form}
                            onFinish={handleSubmit}
                            initialValues={{ email: "", password: "" }}
                        >
                            <Title level={1}>Авторизация</Title>
                            <span className={styles.authSpan}>Введите логин и пароль</span>
                            <Form.Item
                                name='email'
                                className={styles.formItem}
                                rules={[
                                    { required: true, message: "Пожалуйста, введите email" },
                                    {
                                        type: "email",
                                        message: "Некорректный email"
                                    }
                                ]}
                            >
                                <CInput name='email' label='Логин' />
                            </Form.Item>

                            <Form.Item
                                name='password'
                                className={styles.formItem}
                                rules={[{ required: true, message: "Пожалуйста, введите пароль" }]}
                            >
                                <CInput name='password' label='Пароль' type='password' />
                            </Form.Item>

                            <Form.Item className={styles.formItem}>
                                <Button className={styles.button} type='primary' htmlType='submit'>
                                    Вход
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={15} className={styles.authRightSide}>
                        <img className={styles.authLogo} src='/auth.png' alt='Logo' />
                    </Col>
                </Row>
            </Layout>
        </Spin>
    );
};
