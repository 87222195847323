import React, { FC } from "react";
import { CSearch } from "@components/Search";
import { Table, Spin } from "antd";
import { PlusOutlined, EditOutlined, CheckSquareOutlined, BorderOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";
import { CButton } from "@components/Button";
import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import AddModal from "./AddModal/AddModal";
import { useFeaturedProductLineApi } from "@api/featuredProductLine";
import { IFeaturedProductLine } from "./ts/interfaces";
import { DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import EditModal from "./EditModal/EditModal";
import DeleteModal from "./DeleteModal/DeleteModal";
import { IFeaturedProduct } from "./ts/interfaces";
import { IFilter, INITIAL_FILTER } from "./ts/interfaces";

export const FeaturedProduct: FC = () => {
    const [loading, setLoading] = useState(false);
    const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [filter, setFilter] = React.useState<IFilter>({ ...INITIAL_FILTER });
    const [isEditModalOpen, setEditModalOpen] = useState<IFeaturedProduct>({ open: false, data: undefined });
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<IFeaturedProduct>({
        open: false,
        data: undefined
    });
    const { getFeaturedProductLineList } = useFeaturedProductLineApi();
    const [data, setData] = useState<IFeaturedProductLine[]>([]);
    const clickHandler = () => {
        setAddModalOpen(true);
    };

    const handleSearch = (name: string) => (value: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const handleEditModal = (record: any) => () => {
        setEditModalOpen({ open: true, data: record });
    };

    const handleDeleteModal = (record: any) => () => {
        setDeleteModalOpen({ open: true, data: record });
    };

    const getData = useDebouncedCallback(async (filter) => {
        try {
            const res = await getFeaturedProductLineList(filter);
            setData(res);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    }, 300);

    const closeEditModalHandler = () => {
        setEditModalOpen({ open: false, data: undefined });
        getData(filter);
    };

    useEffect(() => {
        setLoading(true);
        getData(filter);
    }, [getData, filter]);

    const columns: ColumnsType<IFeaturedProductLine> = React.useMemo(
        () => [
            {
                title: "Наименование",
                dataIndex: "name",
                key: "name",
                width: "50%"
            },
            {
                title: "Cтатус",
                dataIndex: "isActive",
                key: "isActive",
                render: (value: boolean) => (
                    <span className={value ? styles.active : styles.inactive}>
                        {value ? "Активно" : "Не активно"}
                    </span>
                ),
                width: "20%"
            },
            {
                title: "Коллекция?",
                dataIndex: "isCollection",
                key: "isCollection",
                render: (value: boolean) => (
                    <span className={styles.status}>
                        {value ? (
                            <CheckSquareOutlined className={styles.checkbox} style={{ color: "green" }} />
                        ) : (
                            <BorderOutlined className={styles.checkbox} />
                        )}
                    </span>
                ),
                width: "20%"
            },
            {
                title: "Действия",
                dataIndex: "id",
                key: "id",
                render: (text: any, record: any) => (
                    <>
                        <EditOutlined onClick={handleEditModal(record)} className={styles.editIcon} />
                        <DeleteOutlined onClick={handleDeleteModal(record)} className={styles.deleteIcon} />
                    </>
                )
            }
        ],
        []
    );

    const handleSubmit = () => {
        setEditModalOpen({ open: false, data: undefined });
        getData(filter);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen({ open: false, data: undefined });
        getData(filter);
    };
    const closeAddModal = () => {
        setAddModalOpen(false);
    };

    return (
        <Spin spinning={loading}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.filterRow}>
                        <CSearch value={filter.search} onChange={handleSearch("search")} />
                    </div>
                    <div className={styles.editRow}>
                        <CButton icon={<PlusOutlined />} label="Добавить" onClick={clickHandler} />
                    </div>
                </div>

                <Table<IFeaturedProductLine>
                    dataSource={data}
                    style={{ marginTop: "30px" }}
                    columns={columns}
                    pagination={false}
                    rowClassName={styles.row}
                />
                <AddModal
                    isModalOpen={isAddModalOpen}
                    closeForm={closeAddModal}
                    handleSubmit={handleSubmit}
                ></AddModal>
                <EditModal
                    fProductLine={isEditModalOpen.data}
                    isOpen={isEditModalOpen.open}
                    onClose={closeEditModalHandler}
                ></EditModal>
                <DeleteModal
                    fProductLine={isDeleteModalOpen.data}
                    isOpen={isDeleteModalOpen.open}
                    onClose={closeDeleteModal}
                ></DeleteModal>
            </div>
        </Spin>
    );
};
