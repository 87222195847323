import React, { FC } from "react";
import { Col, Form, Modal, Row, Switch } from "antd";
import { CInput } from "@components/Input";
import { CButton } from "@components/Button";
import styles from "./styles.module.scss";
import { IUser } from "../index";
import { DefaulSelect } from "@components/Select";
import { useUser } from "@api/user";
import { useRegion } from "@api/region";
import { useState, useEffect } from "react";
import { EMAIL, FULL_NAME, REQUIRED } from "@utils/validation";
import clsx from "clsx";
import { parseIanaCode } from "@utils/helpers";
import { generateTimezonesArray, TimeZones } from "@pages/User/constants";
import { useConfirmationDialog } from "@hooks/UI/useConfirmationDialog";
import { ITimezoneItem } from "@hooks/interfaces";

interface IUserModal {
    user?: IUser;
    isOpen: true | false;
    onClose: () => void;
}
export interface IRegion {
    id: number;
    name: string;
}

export const UserModal: FC<IUserModal> = ({ user, isOpen, onClose }) => {
    const [form] = Form.useForm();

    const { getRegionList } = useRegion();
    const { createUser, updateUser } = useUser();
    const [admin, setAdmin] = React.useState(user?.isAdmin);
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [timezones, setTimeZones] = useState<ITimezoneItem[]>(generateTimezonesArray())

    const typesRegionsOption = React.useMemo(() => {
        return regions?.map((item: IRegion) => ({ id: item.id, label: item.name }));
    }, [regions]);
    const timezonesOption =
        timezones?.map( (timezone) => ({ id: timezone.id, label: timezone.label }));

    const getTypeId = (regions: { id: number; name: string }[] | null, value: number) => {
        if (regions?.length) {
            for (const type of regions) {
                if (type.id === value) {
                    return type.id;
                }
            }
        }
    };

    useEffect(() => {
        const getRegionsList = async () => {
            const regionList: IRegion[] = await getRegionList();
            setRegions(regionList);
        };
        getRegionsList().catch((e) => console.error(e));
        const timezones = generateTimezonesArray();
        setTimeZones(timezones);
    }, [getRegionList]);

    React.useEffect(() => {
        setAdmin(user?.isAdmin);
    }, [user]);

    React.useEffect(() => {
        form.setFieldsValue({
            ...user,
            name: user ? `${user?.lastName || ""} ${user?.firstName || ""} ${user?.middleName || ""}` : "",
            isAdmin: user?.isAdmin,
            password: "",
            timezone: user ? parseIanaCode(user.timezone) : "",
        });
    }, [form, user]);

    const getTimezoneValueFromLocalizedString = (localizedString: string) =>
        timezones.find(item => item.label === localizedString)?.ianaCode;

    const handleSubmit = async (value: any) => {
        const splitName = value.name.split(" ");
        value.lastName = splitName[0];
        value.firstName = splitName[1];
        value.middleName = splitName[2];
        value.isAdmin = admin;
        //the object was copied for the purpose of preventing original data model mutating
        const valueCopy = {...value, timezone: getTimezoneValueFromLocalizedString(value.timezone)}

        if (user) {
            valueCopy.id = user.id;
            await updateUser(valueCopy);
        } else {
            await createUser(valueCopy);
        }

        closeModal();
    };

    const closeModal = () => {
        form.resetFields();
        setAdmin(false);
        onClose();
    };

    const adminChangeHandler = () => {
        setAdmin(!admin);
    };
    const selectChangeHandler = (value: any) => form.setFieldsValue({ regionId: getTypeId(regions, value) });

    const getTimezoneValue = (index: number) => timezones.find( item => item.id === index)?.label

    const selectTimezoneChangeHandler = (index: any) => form.setFieldsValue({ timezone: getTimezoneValue(index)});
    

    return (
        <Modal visible={isOpen} onCancel={closeModal} footer={false} className={styles.modal}>
            <Form
                form={form}
                name="signin"
                style={{ width: "100%", padding: "15px 20px" }}
                onFinish={handleSubmit}
            >
                <Form.Item name="name" rules={[REQUIRED, FULL_NAME]}>
                    <CInput name="name" label="ФИО" />
                </Form.Item>

                <Form.Item name="email" rules={[REQUIRED]}>
                    <CInput name="email" label="Email" />
                </Form.Item>

                <Row justify="space-between">
                    <Col>
                        {/* <Form.Item
              name='login'
              rules={[REQUIRED]}
            >
              <CInput
                name='login'
                label='Логин'
              />
            </Form.Item> */}
                        <Form.Item name="position" rules={[REQUIRED]}>
                            <CInput name="position" label="Должность" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="password" rules={user ? [] : [REQUIRED]}>
                            <CInput name="password" label="Пароль" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="regionId" rules={[REQUIRED]}>
                    <DefaulSelect
                        options={typesRegionsOption}
                        label="Регион"
                        onChange={selectChangeHandler}
                    />
                </Form.Item>

                <Form.Item name="timezone" rules={[REQUIRED]}>
                    <DefaulSelect
                        options={timezonesOption}
                        label="Часовой пояс"
                        onChange={selectTimezoneChangeHandler}
                    />
                </Form.Item>

                {/* <Form.Item
          name='email'
          rules={[REQUIRED, EMAIL]}
        >
          <CInput
            name='email'
            label='Email'
          />
        </Form.Item> */}

                <Form.Item name="isAdmin">
                    <div className={styles.switchContainer}>
                        <Switch
                            checked={admin}
                            className={clsx(styles.switcher, admin && styles.switcher_active)}
                            onChange={adminChangeHandler}
                        />
                        <span>Администратор</span>
                    </div>
                </Form.Item>

                <CButton
                    label={user ? "Изменить пользователя" : "Добавить пользователя"}
                    htmlType="submit"
                    cClass={styles.button}
                />
            </Form>
        </Modal>
    );
};
