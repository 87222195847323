import React, { FC, useState } from "react";
import { Card, DatePicker, Spin, Typography } from "antd";
import styles from "./styles.module.scss";
import { CButton } from "@components/Button";
import { parseResponseAsFile } from "@utils/helpers";
import { useNotification } from "@hooks/UI/useNotification";
import axios from "@axios";
import { RangePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/ru_RU";

const { RangePicker } = DatePicker;


export const ProductLineReport: FC = () => {
    const { UseDefaultNotification } = useNotification();
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const getLinesReport = async () => {
        try {
            setLoading(true);
            return await axios({
                method: "GET",
                url: `/report/lines-report?startDate=${startDate}&endDate=${endDate}`,
                responseType: "blob"
            });
        } catch {
        } finally {
            setLoading(false);
        }
    };

    const downloadLinesReport = async () => {
        setLoading(true);
        UseDefaultNotification("warning", {
            message: "Внимание",
            description: "Формирование и подготовка отчёта к скачиванию. Пожалуйста, подождите",
            duration: 5
        });
        try {
            const res: any = await getLinesReport();
            if (res && res.size) {
                parseResponseAsFile(res, "Отчёт по линейкам", startDate, endDate);
                UseDefaultNotification("success", {
                    message: null,
                    description: "Отчёт по линейкам успешно выгружен.",
                    duration: 5
                });
            } else {
                UseDefaultNotification("warning", {
                    message: "Внимание",
                    description: "В базе данных нет сохранённых линеек за выбранный период.",
                    duration: 5
                });
            }
        } catch (e) {
            console.error(e);
            UseDefaultNotification("error", {
                message: "Ошибка",
                description: "Ошибка при скачивании отчёта.",
                duration: 5
            });
        }
        setLoading(false);
    };

    const onDateChange: RangePickerProps["onChange"] = (dates) => {
        if (dates && dates[0] && dates[1]) {
            let startDateFromRangePicker = dates[0].toDate();
            startDateFromRangePicker.setHours(0, 0, 0, 0);
            let endDateFromRangePicker = dates[1].toDate();
            endDateFromRangePicker.setHours(23, 59, 59, 0);
            setStartDate(startDateFromRangePicker);
            setEndDate(endDateFromRangePicker);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    return (
        <Card className={styles.card}>
            <Typography.Title style={{ marginBottom: "24px" }} level={3}>
                Выгрузить отчётность по линейкам
            </Typography.Title>
            {/* @ts-ignore */}
            <RangePicker
                locale={locale}
                className={styles.range_picker}
                // showTime={{ format: "HH:mm" }}
                format="DD-MM-YYYY"
                onChange={onDateChange} />
            <p className={styles.info}>
                Выгрузить отчётность по всем доступным продуктовым линейкам в формате
                XLSX
            </p>
            <CButton
                onClick={downloadLinesReport}
                cClass={styles.button}
                label="Выгрузить отчёт по линейкам"
                disabled={isLoading}
            />
            <Spin spinning={isLoading} className={styles.spin_color} style={{ marginLeft: "10px" }}>
            </Spin>
        </Card>
    );
};
