import React from "react";
import styles from "@pages/Outlet/styles.module.scss";
import { CButton } from "@components/Button";
import { EditOutlined, FilterOutlined } from "@ant-design/icons";
import { IHeaderProps, IUser } from "@pages/Outlet/ts/iterfaces";
import { СFilterableSelect } from "@components/Select";
import { IOption } from "@components/Select/interfaces";
import { Link } from "react-router-dom";

export const OutletHeader: React.FC<IHeaderProps> = ({
    isEditable,
    handleEdit,
    usersList,
    value,
    onSelect,
    onDownloadClick,
    onFilterClear
}) => {
    return (
        <>
            <div className={styles.header}>
                <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "450px" }}>
                    <CButton onClick={onFilterClear} icon={<FilterOutlined />} label='Сбросить фильтры' />
                    <CButton
                        onClick={handleEdit}
                        icon={<EditOutlined />}
                        label={isEditable ? "Сохранить" : "Редактировать"}
                        background={true}
                    />
                </div>
                <div>
                    {isEditable ? (
                        <СFilterableSelect
                            label={"Передать торговому представителю"}
                            options={usersList.map(
                                (user: IUser): IOption => ({ id: user.id, label: user.fullName })
                            )}
                            value={value ? value.fullName : undefined}
                            onSelect={onSelect}
                            bordered={true}
                        />
                    ) : (
                        <CButton label={"Выгрузить в excel"} onClick={onDownloadClick} />
                    )}
                    <Link to='/CreateOutlet'>
                        <CButton label={"Добавить торговую точку"} />
                    </Link>
                </div>
            </div>
        </>
    );
};
