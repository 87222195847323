import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { notification } from "antd";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 120000
});

const errorHandler = (error: any) => {
    if (error?.response?.status === 401) {
        window.location.replace("/");
    }
    return Promise.reject(error);
};

const resHandler = (response: AxiosResponse) => {
    let { data } = response;

    if (data.error) {
        notification.error({
            message: data.error.message
        });
        return Promise.reject(data.error);
    }

    return Promise.resolve(data);
};

const reqHandler = (config: AxiosRequestConfig) => {
    config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
    return config;
};

axiosInstance.interceptors.request.use(reqHandler);
axiosInstance.interceptors.response.use(resHandler, errorHandler);


export default axiosInstance;