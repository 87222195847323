import { useNotification } from "@hooks/UI/useNotification";
import React from "react";
import axios from "@axios";

export const useRegion = () => {
    const { UseDefaultNotification } = useNotification();
    const [loading, setLoading] = React.useState(false);

    const getRegionList = React.useCallback(async () => {
        try {
            setLoading(true);
            const res = await axios.get("/region");
            return res.data;
        } catch {
            UseDefaultNotification("error", {
                message: "Ошибка",
                description: "Ошибка при загрузке списка торговых точек.",
                duration: 5
            });
        } finally {
            setLoading(false);
        }
    }, [UseDefaultNotification]);

    return { loading, getRegionList };
};