import { IRequestQueries, IColumnsName, IColumnFilter } from "@pages/Outlet/ts/iterfaces";
import { ColumnsNameType, SortType } from "@pages/Outlet/ts/types";
import { FilterValue } from "antd/es/table/interface";
import { ASC, ascOrderString, DESC } from "@pages/Outlet/utils/constants";

export const getValidFilter = (
    filter: IColumnsName,
    column: ColumnsNameType | string,
    value: FilterValue | string | null
): IColumnsName => {
    switch (column) {
        case "taxNumber":
            // todo check
            if (value) {
                const tinValue = parseInt(value.toString(), 10);
                if (!isNaN(tinValue)) {
                    return { ...filter, [column]: tinValue };
                } else {
                    throw Error();
                }
            } else {
                return { ...filter, [column]: null };
            }
        case "region":
            return { ...filter, [column + "Id"]: value };
        case "user":
            return { ...filter, [column + "Id"]: value };
        default:
            return { ...filter, [column]: value };
    }
};

export const getValidSortParam = (columnName: string, order: string): SortType => order ?
    order === ascOrderString ? ASC : DESC
    : null;

const getQueriesValue = (paramsObj: any) => {
    const result: { [key: string]: string } = {};
    Object.keys(paramsObj).forEach((key) => {
        if (paramsObj[key]) {
            result[key] = paramsObj[key];
        }
    });
    return Object.keys(result).length ? result : null;
};

export const getRequestQuery = (filter: IColumnsName, sort: IColumnsName): IRequestQueries => ({
    filterBy: getQueriesValue(filter),
    sortBy: getQueriesValue(sort)
});

export const getFilteredValues = (filter: IColumnsName, dataIndex: ColumnsNameType): string[] | null => {
    const result = filter[dataIndex]
    if (result !== null) {
        return [result.toString()]
    } else return null;
}

export const getFilteredValuesLabels = (filter: IColumnsName, dataIndex: ColumnsNameType, filterLabels: any): string[] | null => {
    const filteredIds = filter[dataIndex]
    if (filteredIds !== null && Array.isArray(filteredIds)) {
        return filterLabels.filter((el: IColumnFilter) => filteredIds.includes(el.value)).map((el: IColumnFilter) => el.value)
    } else return null
}
