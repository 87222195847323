import { IColumnsName } from '@pages/Outlet/ts/iterfaces'

export const defaultColumnsState: IColumnsName = {
  tin: null,
  taxNumber: null,
  legalName: null,
  regionId: null,
  city: null,
  address: null,
  userId: null
};

export const ascOrderString = 'ascend';
export const ASC = 'ASC';
export const DESC = 'DESC';
