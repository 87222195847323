import React from "react";
import styles from "./styles.module.scss";
import { Form, Modal } from "antd";
import { CTextArea } from "@components/Textarea";
import { CButton } from "@components/Button";
import { IDeleteOneOutlet } from "@pages/Outlet/ts/iterfaces";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
interface IModalProps {
    isModalOpen: boolean;
    onSend: (data: IDeleteOneOutlet, setDisableButton: (value: boolean) => void) => void;
    onClose: () => void;
    outletId: React.Key;
}

const DeleteOneElemModal: React.FC<IModalProps> = ({ isModalOpen, onClose, onSend, outletId }) => {
    const [userComent, setUserComment] = useState<string | undefined>();
    const [isButtonDisable, setDisableButton] = useState<boolean>(false);
    const [form] = useForm();
    const handleSubmit = () => {
        setDisableButton(true);
        let data: IDeleteOneOutlet = {
            id: outletId,
            deleteComment: userComent
        };
        form.resetFields();
        onSend(data, setDisableButton);
    };
    const closeForm = () => {
        form.resetFields();
        onClose();
    };
    const inputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUserComment(e.target.value);
    };
    return (
        <>
            <Modal
                visible={isModalOpen}
                onOk={handleSubmit}
                onCancel={closeForm}
                footer={false}
                className={styles.modal}
            >
                <p className={styles.text}>Вы уверены, что хотите деактивировать выбранную торговую точку?</p>
                <Form form={form}>
                    <Form.Item name='comment'>
                        <CTextArea
                            onInput={inputHandler}
                            name='comment'
                            label='Комментарий'
                            rows={5}
                            value={userComent}
                        />
                    </Form.Item>
                </Form>
                <CButton
                    label='Деактивировать'
                    htmlType='submit'
                    className={isButtonDisable ? styles.disable_button : styles.button}
                    onClick={handleSubmit}
                    disabled={isButtonDisable}
                />
            </Modal>
        </>
    );
};
export default DeleteOneElemModal;
