import React from "react";
import axios from "@axios";
import { notification } from "antd";

export const useSecret = () => {

    const uploadUsers = React.useCallback(async (data) => {
        try {
            const response = await axios.post("/secret/user", data);
            notification.success({ message: response.statusText });
        } catch (err: any) {
            throw Error(err);
        }
    }, []);

    const uploadOutlets = React.useCallback(async (data) => {
        try {
            const response = await axios.post("/secret/outlet", data);
            // return response
            return notification.success({ message: response.statusText });
        } catch (err: any) {
            throw Error(err);
        }
    }, []);

    const uploadContacts = React.useCallback(async (data) => {
        try {
            const response = await axios.post("/secret/contact", data);
            return notification.success({ message: response.statusText });
        } catch (err: any) {
            throw Error(err);
        }
    }, []);

    return {
        uploadUsers, uploadOutlets, uploadContacts
    };
};