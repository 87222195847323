import React, { useCallback, useRef } from "react";
import { IOutlet, IRegion, IUser } from "@pages/Outlet/ts/iterfaces";
import { HandleResetPropsType, HandleSearchPropsType, OutletColumnsType } from "@pages/Outlet/ts/types";
import { ColumnsType } from "antd/es/table";
import { TableFilter } from "@components/TableFilter";
import { parseDate } from "@utils/helpers";
import { getFilteredValuesLabels } from "@pages/Outlet/utils/helpers";
import { DeleteOutlined,EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

export const OutletColumns: OutletColumnsType = (props) => {
    const {
        handleFiltersChange,
        regionsFilter,
        usersFilter,
        filter,
        setModalOneElemDelete,
        setDeleteElement
    } = props;
    const { getColumnSearchProps } = TableFilter();
    const history = useHistory();
    // @ts-ignore
    const searchInput = useRef<Input>(null);
    const deleteIconClick = (data: React.Key) => {
        setDeleteElement(data);
        console.log("data",data);
        setModalOneElemDelete(true);
    };

    const handleSearch = useCallback<HandleSearchPropsType>(
        ({ selectedKeys, confirm, dataIndex }) => {
            confirm();
            handleFiltersChange(dataIndex, selectedKeys[0]);
        },
        [handleFiltersChange]
    );

    const handleReset = useCallback<HandleResetPropsType>(
        ({ clearFilters, dataIndex }) => {
            clearFilters();
            handleFiltersChange(dataIndex, null);
        },
        [handleFiltersChange]
    );
    const handleDelete = (record: any) => {
        deleteIconClick(record.id);
    };
    const handleEdit = (record: any) =>{
       history.push({pathname:"/edit-outlet", state:{record}});
    }

    const tableColumns = (): ColumnsType<IOutlet> => [
        {
            title: "ИНН",
            dataIndex: "taxNumber",
            key: "taxNumber",
            width: "10%",
            sorter: true,
            showSorterTooltip: false,
            ...getColumnSearchProps("taxNumber", searchInput, handleSearch, handleReset, filter)
        },
        {
            title: "Название",
            dataIndex: "legalName",
            key: "legalName",
            width: "20%",
            sorter: true,
            showSorterTooltip: false,
            ...getColumnSearchProps("legalName", searchInput, handleSearch, handleReset, filter)
        },
        {
            title: "Регион",
            dataIndex: "region",
            key: "region",
            width: "10%",
            filters: regionsFilter,
            filteredValue: getFilteredValuesLabels(filter, "regionId", regionsFilter),
            render: (value: IRegion) => value.name
        },
        {
            title: "Город",
            dataIndex: "city",
            key: "city",
            width: "10%",
            sorter: true,
            showSorterTooltip: false,
            ...getColumnSearchProps("city", searchInput, handleSearch, handleReset, filter),
            render: (text: string, value: IOutlet) =>
            (value.city !== "null" && value.city !== "undefined") ? (
                <span>{value.city}</span>
            ) : (
                <span></span>
            )
        },
        {
            title: "Адрес",
            dataIndex: "address",
            key: "address",
            width: "20%",
            sorter: true,
            showSorterTooltip: false,
            ...getColumnSearchProps("address", searchInput, handleSearch, handleReset, filter),
            render: (text: string, value: IOutlet) =>
                (value.address !== "null" && value.address !== "undefined")  ? (
                    <span>{value.address}</span>
                ) : (
                    <span></span>
                )
        },
        {
            title: "Пользователь",
            dataIndex: "user",
            key: "user",
            width: "26%",
            filters: usersFilter,
            filteredValue: getFilteredValuesLabels(filter, "userId", usersFilter),
            render: (value: IUser) => value.fullName
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            width: "10%",
            sorter: true,
            showSorterTooltip: false,
            render: (value: boolean) =>
                value ? (
                    <span className={styles.activeStatus}>Активно</span>
                ) : (
                    <span className={styles.unActiveStatus}>Не активно</span>
                )
        },
        {
            title: "Последний визит",
            dataIndex: "lastVisit",
            key: "lastVisit",
            width: "10%",
            showSorterTooltip: false,
            render: (text: string, value: IOutlet) =>
                value.lastVisit ? (
                    <span>{parseDate(value.lastVisit)}</span>
                ) : (
                    <span className={styles.lastVisit}>-</span>
                )
        },
        {
            title: "Дата деактивации",
            key: "date",
            dataIndex: "date",
            render: (text: string, record: any) =>
                record.deletedAt != null ? <span>{parseDate(record.deletedAt)}</span> : <span></span>,
            width: "12%"
        },
        {
            title: "Комментарий",
            key: "comentOnDelete",
            dataIndex: "comentOnDelete",
            render: (text: string, record: any) => record.deleteComment,
            width: "10%"
        },

        {
            title: "",
            key: "id",
            dataIndex: "id",
            render: (text: string, record: any) =>
                record.status === true ? (
                    <div className={styles.editDeleteContainer}>
                    <EditOutlined onClick={() => handleEdit(record)} className={styles.editIcon}  />
                    <DeleteOutlined onClick={() => handleDelete(record)} className={styles.deleteIcon} />
                    </div>
                ) : (
                    <span></span>        
                ),
            width: "6%"
        }
    ];

    return tableColumns();
};
