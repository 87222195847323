import { Card, Col, Row, Typography } from "antd";
import React, { FC } from "react";
import { CButton } from "@components/Button";
import styles from "./styles.module.scss";
import { parseDate, reduceString } from "@utils/helpers";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

const { Title } = Typography;

export interface IMedia {
    id: number;
    file?: string;
    preview: string;
    mediaTypeId: number;
    name: string;
    createdAt: Date;
    type: { name: string };
    description: string;
}

interface IMediaCard {
    card: IMedia
    onClick?: any
    active?: true | false
    hover: true | false,
    onDownload: (value: string | undefined) => () => void
}

interface IDescription {
    text: string;
    buttonText: string;
    onClick: () => void;
}

export const MediaCard: FC<IMediaCard> = ({ card, onClick, onDownload, active, hover }) => {

    const history = useHistory();

    const [textHide, setTextHide] = React.useState(true);

    const changeButtonClick = () => {
        history.push("/media/manage", { ...card });
    };

    const maxTextLength = 150;

    const handleClick = (id: number) => () => {
        return onClick(id);
    };

    const textHideChangeHandler = (value: boolean) => () => {
        setTextHide(value);
    };

    return (
        <Card
            onClick={handleClick(card.id)}
            key={card.id}
            className={clsx(styles.card, active && styles.active, hover && styles.hover, !textHide && styles.height_full)}
            bordered={false}
        >
            <Row>
                <Col className={styles.imgCol} span={4}>
                    <img className={styles.cardImg} src={card.preview} alt={card.name} />
                </Col>
                <Col span={19} offset={1}>
                    <Row justify="space-between" style={{ marginTop: "40px" }}>
                        <Col><Title level={2}>{card.name}</Title></Col>
                        <Col style={{ marginRight: "40px", marginTop: "-20px" }}><Title
                            level={4}>{parseDate(card.createdAt)}</Title></Col>
                    </Row>
                    <Row>
                        <Col><span className={styles.cardType}>{card.type.name}</span></Col>
                    </Row>
                    <Row>
                        <Col>
                            {card.description.length < maxTextLength
                                ? <p className={styles.cardDescription}>{card.description}</p>
                                : textHide
                                    ? <Description
                                        text={reduceString(card.description, maxTextLength, maxTextLength)}
                                        buttonText={"Показать"}
                                        onClick={textHideChangeHandler(false)}
                                    />
                                    : <Description
                                        text={card.description}
                                        buttonText={"Скрыть"}
                                        onClick={textHideChangeHandler(true)}
                                    />
                            }
                        </Col>
                    </Row>
                    <Row className={styles.buttonRow} justify="end" style={{ marginRight: "40px" }}>
                        <Col onClick={onDownload(card.file)} style={{ marginRight: "20px" }}><CButton label="Скачать" /></Col>
                        <Col><CButton onClick={changeButtonClick} background={true} label="Изменить" /></Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

const Description: FC<IDescription> = ({ text, buttonText, onClick }) => {
    return (
        <p className={styles.cardDescription}>
            {text}
            <span
                onClick={onClick}
                className={styles.descriptionButton}
            >
        {buttonText}
      </span>
        </p>
    );
};