import { notification } from "antd";
import { useCallback } from "react";
import { DefaultNotificationArgs, NotificationType, AuthorizationNotificationArgs } from "@hooks/types";

export const useNotification = () => {
    const UseDefaultNotification = useCallback(
        (type: NotificationType, args: DefaultNotificationArgs | AuthorizationNotificationArgs): void => {
            notification[type](args);
        },
        []
    );

    return {
        UseDefaultNotification
    };
};
