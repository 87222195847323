import styles from "./styles.module.scss";
import React, { FC } from "react";
import { Button, Card, Typography } from "antd";

export const Logout: FC = () => {

    const logout = () => {
        localStorage.clear();
        returnToBaseUrl();
    };

    const returnToBaseUrl = () => {
        // eslint-disable-next-line no-restricted-globals
        window.location.href = location.origin;
    };

    return (
        <Card className={styles.card}>
            <div style={{ width: "300px", margin: "0 auto" }}>
                <Typography.Title style={{ marginBottom: "24px" }} level={3}>
                    Выйти из приложения?
                </Typography.Title>
            </div>
            <div style={{ width: "360px", margin: "0 auto" }}>
                <Button
                    className={styles.accept_button}
                    type="primary"
                    htmlType="submit"
                    onClick={returnToBaseUrl}
                >
                    Нет
                </Button>
                <Button
                    className={styles.error_button}
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "25px" }}
                    onClick={logout}>
                    Да
                </Button>
            </div>
        </Card>
    );
};