import React, { FC } from "react";
import { Input } from "antd";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";

interface ICustomSearch {
    onChange?: any
    placeholder?: string,
    className?: string | undefined
    value?: string
}

export const CSearch: FC<ICustomSearch> = ({ onChange, placeholder = " ", className, value }) => {

    const [text, setText] = React.useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        onChange ? onChange(val) : setText(val);
    };

    const handleClear = () => {
        onChange("");
    };

    return (
        <div style={{ display: "flex", position: "relative" }}>
            <div className={styles.container}>
                <div className={styles.searchCont}>
                    <Input
                        type="text"
                        name="search"
                        value={value ? value : text}
                        onChange={handleChange}
                        placeholder={placeholder}
                        className={clsx(styles.input, className)}
                    />
                    <label className={styles.searchLabel} htmlFor="search"><span
                        className={styles.searchSpan}>Поиск</span></label>
                </div>
                <SearchOutlined className={styles.searchInput} />
            </div>
            <CloseCircleOutlined
                className={clsx(value !== "" ? styles.deleteValue : styles.hidden)}
                onClick={handleClear}
            />
        </div>
    );
};