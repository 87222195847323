import React from "react";
import axios from "@axios";
import { useNotification } from "@hooks/UI/useNotification";
import { IDeleteOneOutlet, IRequestQueries } from "@pages/Outlet/ts/iterfaces";
import { notification } from "antd";

export const useOutlet = () => {
    const { UseDefaultNotification } = useNotification();
    const [isLoading, setLoading] = React.useState(false);
    const getOutletsList = React.useCallback(
        async (requestQueries: IRequestQueries) => {
            try {
                setLoading(true);
                const res = await axios.get("/outlet", {
                    params: requestQueries,
                    headers: { Authorization: `${localStorage.token}` }
                });
                return res.data;
            } catch {
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: "Ошибка при загрузке списка торговых точек.",
                    duration: 3
                });
            } finally {
                setLoading(false);
            }
        },
        [UseDefaultNotification]
    );

    const createOutlet = React.useCallback(
        async (outlet) => {
            try {
                const res = await axios.post("/outlet", outlet);
                notification.success({ message: res.statusText });
            } catch (e: any) {
                let text: string;
                const response = e.response?.data;
                if (response && response.status === 400) {
                    text = response.error.message;
                } else if (e.message === "Network Error") {
                    text = "Создать торговую точку не удалось. Возникла ошибка сети";
                }
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: text!,
                    duration: 3
                });
            }
        },
        [UseDefaultNotification]
    );

    const deleteOutlet = React.useCallback(
        async (outlet: IDeleteOneOutlet) => {
            try {
                const res = await axios.delete("/outlet", {
                    data: { outlet: outlet }
                });
                notification.success({ message: res.statusText });
            } catch (e) {
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: "Ошибка при удалении торговых точек.",
                    duration: 3
                });
            }
        },
        [UseDefaultNotification]
    );

    const updateOutlet = React.useCallback(
        async (outlet) => {
            try {
                const res = await axios.put("/outlet", outlet);
                notification.success({ message: res.statusText });
            } catch (e) {
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: "Ошибка при редактировании торговой точки.",
                    duration: 3
                });
            }
        },
        [UseDefaultNotification]
    );

    const updateOutletsUser = React.useCallback(
        async (outletIds: React.Key[], newUserId: number) => {
            try {
                setLoading(true);
                const res = await axios.put("/outlet/change-user", {
                    outletIds: outletIds,
                    newUserId: newUserId
                });
                return res.data;
            } catch (e) {
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: "Ошибка при обновлении списка торговых точек.",
                    duration: 3
                });
            } finally {
                setLoading(false);
            }
        },
        [UseDefaultNotification]
    );

    const downloadOutletsReport = React.useCallback(
        async (requestQuery: IRequestQueries) => {
            try {
                setLoading(true);
                return await axios({
                    method: "GET",
                    url: "/outlet/report",
                    responseType: "blob",
                    params: requestQuery
                });
            } catch (e) {
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: "Ошибка при выгрузке списка торговых точек.",
                    duration: 5
                });
            } finally {
                setLoading(false);
            }
        },
        [UseDefaultNotification]
    );

    return {
        isLoading,
        getOutletsList,
        updateOutletsUser,
        downloadOutletsReport,
        createOutlet,
        deleteOutlet,
        updateOutlet
    };
};
