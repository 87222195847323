export interface IFeaturedProductLine {
    id: number;
    name: string;
    isActive: boolean;
    isCollection: boolean;
}

export interface IFilter {
    search: string;
}

export interface IFeaturedProduct {
    open: true | false;
    data: IFeaturedProductLine | undefined;
}

export const INITIAL_FILTER: IFilter = {
    search: ""
};

export interface IFeaturedProductModal {
    fProductLine?: IFeaturedProductLine;
    isOpen: true | false;
    onClose: () => void;
}
