import { Dropdown, Menu, Row, Table,Spin } from "antd";
import React, { FC,useState } from "react";
import { CSearch } from "@components/Search";
import { CSelect } from "@components/Select";
import { InputFile } from "@components/Input";
import { CButton } from "@components/Button";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { useDebouncedCallback } from "use-debounce/lib";
import { ProductLineModal } from "./modal/ProductLineModal";
import styles from "./styles.module.scss";
import { useProductLine } from "@api/productLine";
import { reduceString } from "@utils/helpers";
import clsx from "clsx";
import "./styles.scss";
import { IFilter, IModalState, INITIAL_FILTER, IProdLine } from "@pages/Product/productInterfaces";

export const Products: FC = () => {
    const { getProductLineList, deleteProductLine, importFile } =
        useProductLine();
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = React.useState<IFilter>({ ...INITIAL_FILTER });
    const [data, setData] = React.useState<IProdLine[]>([]);
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const [deleteArr, setDeleteArr] = React.useState<Array<number>>([]);
    const [modal, setModal] = React.useState<IModalState>({
        open: false,
        data: undefined
    });

    const dateOptions = React.useMemo(
        () => [
            {
                id: 1,
                label: "Дате добавления",
                icon:
                    filter.sort === 1 || filter.sort === 3 ? (
                        filter.sort === 1 ? (
                            <ArrowUpOutlined />
                        ) : (
                            <ArrowDownOutlined />
                        )
                    ) : undefined
            },
            {
                id: 2,
                label: "Имени",
                icon:
                    filter.sort === 2 || filter.sort === 4 ? (
                        filter.sort === 2 ? (
                            <ArrowUpOutlined />
                        ) : (
                            <ArrowDownOutlined />
                        )
                    ) : undefined
            }
        ],
        [filter]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeFile = (value: any) =>
        new Promise(() => {
            return new Promise(async (resolve) => {
                const formData = new FormData();
                formData.append("file", value.target.files?.item(0));
                const res = await importFile(formData);
                resolve(res);
            }).then(() => {
                getData(filter);
            });
        });

    const openModalHandler = () => {
        setModal({ open: true, data: undefined });
    };

    const columns: ColumnsType<IProdLine> = React.useMemo(
        () => [
            {
                title: "Название",
                dataIndex: "name",
                key: "name",
                width: "17%"
            },
            {
                title: "Производитель",
                dataIndex: "manufacturer",
                key: "manufacturer"
            },
            {
                title: "Тип оборудования",
                dataIndex: "equipmentType",
                key: "equipmentType"
            },
            {
                title: "Статус",
                dataIndex: "status",
                key: "status",
                width: 120,
                render: (value: true | false) =>
                    value ? (
                        <span className={styles.green}>Активно</span>
                    ) : (
                        <span className={styles.red}>Не активно</span>
                    )
            },
            {
                title: "Описание",
                dataIndex: "description",
                key: "description",
                width: "45%",
                render: (value: string) => <span>{reduceString(value, 200, 200)}</span>
            },
            {
                title: "",
                key: "id",
                dataIndex: "id",
                render: (text: string, record: any) => (
                    <EditOutlined
                        onClick={handleEdit(record)}
                        className={styles.editIcon}
                    />
                ),
                width: "10%"
            }
        ],
        []
    );

    const menu = React.useMemo(
        () => (
            <Menu>
                <Menu.Item>
                    <InputFile
                        name="file"
                        onChange={changeFile}
                        label={"Загрузка Excel файла"}
                    />
                </Menu.Item>
                <Menu.Item onClick={openModalHandler}>
                    <span>Ручное добавление</span>
                </Menu.Item>
            </Menu>
        ),
        [changeFile]
    );

    const handleEdit = (record: any) => () => {
        setModal({ open: true, data: record });
    };

    const handleChange = (name: string) => (value: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const handleSelect = (name: string) => (value: string) => {
        if (filter["sort"] === Number(value)) {
            setFilter({ ...filter, sort: Number(filter["sort"] + 2) });
        } else {
            return setFilter({ ...filter, [name]: Number(value) });
        }
    };

    const deleteIconClick = () => {
        setDeleteArr([]);
        setIsDeleting(!isDeleting);
    };

    const handleDelete = async () => {
        const data = {
            id: deleteArr
        };
        await deleteProductLine(data);
        setIsDeleting(false);
        getData(filter);
        setDeleteArr([]);
    };

    const cancelDelete = () => {
        setDeleteArr([]);
        setIsDeleting(false);
    };

    const handleCloseModal = () => {
        setModal({ open: false, data: undefined });
        getData(filter);
    };

    const getData = useDebouncedCallback(async (filter) => {
        try {
            const d = await getProductLineList(filter);
            setData(d);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }

    }, 300);

    React.useEffect(() => {
        setLoading(true);
        getData(filter);
    }, [getData, filter]);
    
    const onRowClick = (record: any) => {
        return {
            onClick: () => {
                if (isDeleting) {
                    if (deleteArr.find((item) => item === record.id)) {
                        const tmp = [...deleteArr].filter((item) => item !== record.id);
                        setDeleteArr(tmp);
                    } else {
                        setDeleteArr([...deleteArr, record.id]);
                    }
                } else {
                    return null;
                }
            }
        };
    };

    return (
        <Spin spinning={loading}>
        <div style={{ padding: "20px 40px" }}>
            <div className={styles.filterRow}>
                <CSearch
                    className={styles.searchInput}
                    value={filter.search}
                    onChange={handleChange("search")}
                />

                <CSelect
                    options={dateOptions}
                    label="Сортировать по"
                    onSelect={handleSelect("sort")}
                />

                <DeleteOutlined
                    onClick={deleteIconClick}
                    className={styles.trashIcon}
                />

                <Dropdown overlay={menu}>
                    <CButton
                        className={styles.dropdown}
                        icon={<PlusOutlined />}
                        label="Добавить"
                    />
                </Dropdown>
            </div>

            {isDeleting && (
                <Row justify="end" style={{ margin: "0 0 20px 20px" }}>
                    <CButton
                        cClass={styles.cancelButton}
                        onClick={cancelDelete}
                        label="Отмена"
                    />
                    <CButton
                        disabled={deleteArr?.length === 0}
                        cClass={clsx(
                            styles.deleteButton,
                            deleteArr?.length === 0 && styles.deleteButtonDisabled
                        )}
                        onClick={handleDelete}
                        label="Удалить"
                    />
                </Row>
            )}

            <Table<IProdLine>
                style={{ marginTop: "30px" }}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowClassName={(record) =>
                    deleteArr.includes(record.id) === true
                        ? clsx(styles.row, isDeleting && styles.delete, styles.active)
                        : clsx(styles.row, isDeleting && styles.delete)
                }
                onRow={onRowClick}
            />
            <ProductLineModal
                pLine={modal.data}
                onClose={handleCloseModal}
                isOpen={modal.open}
            />
        </div>
        </Spin>
    );
};
