import React, { RefObject, useCallback } from "react";
import { HandleResetPropsType, HandleSearchPropsType, ColumnsNameType } from "@pages/Outlet/ts/types";
import { ColumnType } from "antd/es/table";
import { IColumnsName, IOutlet } from "@pages/Outlet/ts/iterfaces";
import { Button, Input, Space } from "antd";
import styles from "@pages/Outlet/styles.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import { getFilteredValues } from "@pages/Outlet/utils/helpers";

type ColumnsSearchProps = (
    dataIndex: ColumnsNameType,
    // @ts-ignore
    searchInput: RefObject<Input>,
    handleSearch: HandleSearchPropsType,
    handleReset: HandleResetPropsType,
    filter: IColumnsName
) => ColumnType<IOutlet>

export const TableFilter = () => {
    const getColumnSearchProps = useCallback<ColumnsSearchProps>(
        (dataIndex,
         searchInput,
         handleSearch,
         handleReset,
         filter) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={"Поиск"}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch({
                            selectedKeys: selectedKeys as string[],
                            confirm,
                            dataIndex
                        })}
                        style={{ marginBottom: 8, display: "block" }}
                        className={styles.searchInput}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch({ selectedKeys: selectedKeys as string[], confirm, dataIndex })}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                            className={styles.searchBtn}
                        >
                            Поиск
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset({ clearFilters, dataIndex })}
                            size="small"
                            style={{ width: 90 }}
                            className={styles.clearBtn}
                        >
                            Очистить
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? "#3dcd58" : "" }} />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
            filteredValue: getFilteredValues(filter, dataIndex)
        }), []);

    return { getColumnSearchProps };
};
