import { Select } from "antd";
import styles from "./styles.module.scss";
import React, { FC } from "react";
import clsx from "clsx";
import { ICustomDefaultSelect, ICustomFilterableSelect, ICustomSelect } from "@components/Select/interfaces";

const { Option } = Select;

export const CSelect: FC<ICustomSelect> = (
    {
        bordered = false, className, label,
        options, onSelect, onChange, defaultValue
    }) => {
    return (
        <div className={styles.selectContent}>
            <span>{label}:</span>
            <Select
                className={clsx(styles.select, className)}
                defaultValue={defaultValue ? defaultValue : options![0]?.label}
                value={defaultValue}
                bordered={bordered}
                onSelect={onSelect}
                onChange={onChange}
            >
                {
                    options ?
                        options.map(o => (
                            <Option key={o.id} value={o.id}>
                                <div className={styles.c_option}>{o.label}{o?.icon}</div>
                            </Option>
                        )) :
                        []
                }
            </Select>
        </div>
    );
};

export const СFilterableSelect: FC<ICustomFilterableSelect> = (
    {
        bordered = false,
        className,
        label,
        options,
        onSelect,
        onChange,
        value
    }) => (
    <div className={styles.selectContent}>
        <span>{label}:</span>
        <Select
            showSearch
            className={clsx(styles.select, className)}
            value={value}
            onSelect={onSelect}
            bordered={bordered}
            onChange={onChange}

            filterOption={(input, option) =>
                // @ts-ignore
                option && option.children.toLowerCase().includes(input.toLowerCase())
            }
        >
            {
                options ?
                    options.map(o => (
                        <Option key={o.id} value={o.id}>{o.label}</Option>)
                    ) :
                    []
            }
        </Select>
    </div>
);


export const DefaulSelect: FC<ICustomDefaultSelect> = (
    {
        options, onChange, value, onSelect,
        className, placeholder = " ", label
    }) => {
    return (
        <div className={styles.defaultSelectContent}>
            <span className={clsx(styles.label,className)}>{label === "" ? "" : label + ':'}</span>
            <Select
                className={clsx(styles.defaultSelect, className)}
                onSelect={onSelect}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            >
                {
                    options ?
                        options.map(o => (
                            <Option key={o.id} value={o.id}>{o.label}</Option>)
                        ) :
                        []
                }
            </Select>
        </div>
    );
};