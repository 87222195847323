import React, { FC, useState } from "react";
import { Form, Modal } from "antd";
import { Col, Row } from "antd";
import { CInput } from "@components/Input";
import { CButton } from "@components/Button";
import { REQUIRED } from "@utils/validation";
import { Checkbox } from "antd";
import { useForm } from "antd/lib/form/Form";
import styles from "./styles.module.scss";
import { DefaulSelect } from "@components/Select";
import { useFeaturedProductLineApi } from "@api/featuredProductLine";

interface ModalProps {
    isModalOpen: boolean;
    closeForm: () => void;
    handleSubmit: (value: any) => void;
}

export const AddModal: FC<ModalProps> = ({ isModalOpen, closeForm, handleSubmit }) => {
    const api = useFeaturedProductLineApi();
    const [form] = useForm();
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isCollection, setIsCollection] = useState<boolean>(false);
    const statusOptions = React.useMemo(
        () => [
            { id: 1, label: "Активно" },
            { id: 2, label: "Не активно" }
        ],
        []
    );
    const sendData = (value: HTMLFormElement) => {
        setButtonDisabled(true);
        value.isCollection = isCollection;
        value.isActive = isActive;
        api.createFeaturedProductLine(value);
        form.resetFields();
        setIsCollection(false);
        setButtonDisabled(false);
        handleSubmit(value);
        closeForm();
    };

    const close = () => {
        form.resetFields();
        closeForm();
    };

    const onChange = () => {
        setIsCollection(!isCollection);
    };

    const selectChangeHandler = (value: any) => {
        setIsActive(value === 1 ? true : false);
    };
    return (
        <>
            {
                <Modal
                    visible={isModalOpen}
                    onOk={handleSubmit}
                    onCancel={close}
                    footer={false}
                    className={styles.modal}
                >
                    <p className={styles.text}>Создание фокусной линейки</p>
                    <Form form={form} onFinish={sendData}>
                        <Form.Item name='name' rules={[REQUIRED]}>
                            <CInput name='name' label='Название' />
                        </Form.Item>
                        <Row justify='space-between' align='middle'>
                            <Col>
                                <Form.Item name='isActive' rules={[REQUIRED]}>
                                    <DefaulSelect
                                        label='Статус'
                                        className={styles.select}
                                        options={statusOptions}
                                        onChange={selectChangeHandler}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name='isCollection'>
                                    <span className={styles.label}>Коллекция?</span>
                                    <Checkbox className={styles.checkbox} onChange={onChange}></Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='center' align='middle' style={{ marginTop: "40px" }}>
                            <Col style={{ marginRight: "20px" }}>
                                <CButton
                                    label='Вернуться'
                                    className={
                                        isButtonDisabled
                                            ? styles.button__cancel__disabled
                                            : styles.button__cancel__active
                                    }
                                    onClick={close}
                                    disabled={isButtonDisabled}
                                />
                            </Col>
                            <Col>
                                <CButton
                                    label='Создать'
                                    htmlType='submit'
                                    className={isButtonDisabled ? styles.button__disabled : styles.button}
                                    disabled={isButtonDisabled}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    );
};

export default AddModal;
