import styles from "./styles.module.scss";
import { FC } from "react";
import { VisitReport } from "./VisitReport";
import { ProductLineReport } from "./ProductLineReport";

export interface IProduct {
    id: number;
    sample: boolean;
    stand: boolean;
    product: {
        id: number
        description: string
        manufacturer: string
        name: string
    };
}

export interface IOutlet {
    id: number;
    tin: number;
    taxNumber: string;
    name: string;
    type: string;
    city: string;
    site: string;
    region: string;
    address: string;
    updatedAt: Date;
    latitude?: number;
    createdAt: Date;
    legalName: string;
    longitude?: number;
    specialization: string;
    user: IUser;
}

export interface IUser {
    id: number;
    email: string;
    position: string;
    fullName: string;
    lastName: string;
    firstName: string;
    middleName: string;
}

export const Reports: FC = () => {

    return (
        <div className={styles.container}>
            <ProductLineReport />
            <VisitReport />
        </div>
    );
};