import { FC, useEffect } from "react";
import styles from "./styles.module.scss";
import { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import { useState } from "react";

interface ITypeOutletProps {
    setSpecialization: (value: String) => void;
    setTypeOutlet: (value: String) => void;
    setButtonDisabled?: (value: boolean) => void;
    defaultValueSp?: string;
    defaultValueType?: string;
}

const TYPES_OUTLET = {
    DEALERSHIP: "Дилерская",
    INDEPENDENT: "Независимая",
    DISTRIBUTION: "Дистрибьюторская"
};

const SPECIALIZATIONS_OUTLET = {
    ELECTRIAN: "Электромонтажника",
    DESIGNER: "Дизайнерская",
    NOT_SELECTED: "Не выбрано"
};

export const TypeOutlet: FC<ITypeOutletProps> = ({
    setSpecialization,
    setTypeOutlet,
    defaultValueSp: defaultValueSpecialization = null,
    defaultValueType = null,
    setButtonDisabled
}) => {
    const [dealership, setDealership] = useState<boolean>(true);
    const [independent, setIndependent] = useState<boolean>(false);
    const [distribution, setDistribution] = useState<boolean>(false);
    const [notselected, setNotSelected] = useState<boolean>(true);
    const [electrician, setElectrician] = useState<boolean>(false);
    const [designer, setDesigner] = useState<boolean>(false);

    useEffect(() => {
        setDefaultSpecialization(defaultValueSpecialization);
        setDefaultType(defaultValueType);
    }, [setTypeOutlet, setSpecialization]);

    const setDefaultSpecialization = (value: any) => {
        if (
            defaultValueSpecialization === null ||
            defaultValueSpecialization === undefined ||
            defaultValueSpecialization === "" ||
            defaultValueSpecialization === "null"
        ) {
            setSpecialization("Не выбрано");
            return;
        }
        setDefaultButtonSp(defaultValueSpecialization);
    };

    const setDefaultType = (value: any) => {
        if (
            defaultValueType === null ||
            defaultValueType === undefined ||
            defaultValueType === "" ||
            defaultValueType === "null"
        ) {
            setTypeOutlet("Дилерская");
            return;
        }
        setDefaultButtonType(defaultValueType);
    };
    const setDefaultButtonSp = (value: string) => {
        switch (value) {
            case "Не выбрано":
                setSpecialization("Не выбрано");
                break;
            case "Электромонтажника":
                let electricianIdLink = document.getElementById("electrician");
                electricianIdLink!.className = styles.radio_button_checked;
                electricianIdLink?.click();
                break;

            case "Дизайнерская":
                let designerIdLink = document.getElementById("designer");
                designerIdLink?.click();
                break;
        }
    };

    const setDefaultButtonType = (value: string) => {
        switch (value) {
            case "Дилерская":
                setTypeOutlet("Дилерская");
                break;
            case "Независимая":
                let independentIdLink = document.getElementById("independent");
                independentIdLink?.click();
                break;

            case "Дистрибьюторская":
                let distributionIdLink = document.getElementById("distribution");
                distributionIdLink?.click();
                break;
        }
    };
    const dealerShipHandler = (e: RadioChangeEvent) => {
        setTypeOutlet(e.target.value);
        setDealership(true);
        setDistribution(false);
        setIndependent(false);
        if (setButtonDisabled && defaultValueType !== TYPES_OUTLET.DEALERSHIP) {
            setButtonDisabled(false);
        }
    };
    const independentHandler = (e: RadioChangeEvent) => {
        setTypeOutlet(e.target.value);
        setIndependent(true);
        setDealership(false);
        setDistribution(false);
        if (setButtonDisabled && defaultValueType !== TYPES_OUTLET.INDEPENDENT) {
            setButtonDisabled(false);
        }
    };
    const distributionHandler = (e: RadioChangeEvent) => {
        setTypeOutlet(e.target.value);
        setDistribution(true);
        setIndependent(false);
        setDealership(false);
        if (setButtonDisabled && defaultValueType !== TYPES_OUTLET.DISTRIBUTION) {
            setButtonDisabled(false);
        }
    };
    const notselectedHandler = (e: RadioChangeEvent) => {
        setSpecialization(e.target.value);
        setNotSelected(true);
        setElectrician(false);
        setDesigner(false);
        if (setButtonDisabled && defaultValueSpecialization !== SPECIALIZATIONS_OUTLET.NOT_SELECTED) {
            setButtonDisabled(false);
        }
    };
    const electricianHandler = (e: RadioChangeEvent) => {
        setSpecialization(e.target.value);
        setElectrician(true);
        setNotSelected(false);
        setDesigner(false);
        if (setButtonDisabled && defaultValueSpecialization !== SPECIALIZATIONS_OUTLET.ELECTRIAN) {
            setButtonDisabled(false);
        }
    };
    const designerHandler = (e: RadioChangeEvent) => {
        setSpecialization(e.target.value);
        setDesigner(true);
        setNotSelected(false);
        setElectrician(false);
        if (setButtonDisabled && defaultValueSpecialization !== SPECIALIZATIONS_OUTLET.DESIGNER) {
            setButtonDisabled(false);
        }
    };
    return (
        <>
            <div className={styles.container}>
                <Radio.Group
                    defaultValue={
                        defaultValueSpecialization === null
                            ? SPECIALIZATIONS_OUTLET.DESIGNER
                            : defaultValueSpecialization
                    }
                    className={styles.group_buttons}
                    size="large"
                >
                    <div className={styles.buttons_flex_container}>
                        <div className={styles.button_element}>
                            <Radio.Button
                                value="Дилерская"
                                id="dealerShip"
                                className={dealership ? styles.radio_button_checked : styles.radio_button}
                                onChange={dealerShipHandler}
                            >
                                Дилерская
                            </Radio.Button>
                        </div>
                        <div className={styles.button_element}>
                            <Radio.Button
                                value="Независимая"
                                id="independent"
                                className={independent ? styles.radio_button_checked : styles.radio_button}
                                onChange={independentHandler}
                            >
                                Независимая
                            </Radio.Button>
                        </div>
                        <div className={styles.button_element}>
                            <Radio.Button
                                value="Дистрибьюторская"
                                id="distribution"
                                className={distribution ? styles.radio_button_checked : styles.radio_button}
                                onChange={distributionHandler}
                            >
                                Дистрибьюторская
                            </Radio.Button>
                        </div>
                    </div>
                </Radio.Group>

                <Radio.Group
                    defaultValue={
                        defaultValueType === null ? SPECIALIZATIONS_OUTLET.NOT_SELECTED : defaultValueType
                    }
                    className={styles.group_buttons}
                    size="large"
                >
                    <div className={styles.buttons_flex_container}>
                        <div className={styles.button_element}>
                            <Radio.Button
                                value="Не выбрано"
                                id="notselected"
                                className={notselected ? styles.radio_button_checked : styles.radio_button}
                                onChange={notselectedHandler}
                            >
                                Не выбрано
                            </Radio.Button>
                        </div>
                        <div className={styles.button_element}>
                            <Radio.Button
                                value="Электромонтажника"
                                id="electrician"
                                className={electrician ? styles.radio_button_checked : styles.radio_button}
                                onChange={electricianHandler}
                            >
                                Электромонтажника
                            </Radio.Button>
                        </div>
                        <div className={styles.button_element}>
                            <Radio.Button
                                value="Дизайнерская"
                                id="designer"
                                className={designer ? styles.radio_button_checked : styles.radio_button}
                                onChange={designerHandler}
                            >
                                Дизайнерская
                            </Radio.Button>
                        </div>
                    </div>
                </Radio.Group>
            </div>
        </>
    );
};
