import styles from "./styles.module.scss";
import { OutletInfo } from "./OutletInfo";

export const CreateOutlet = () => {
    return (
        <div className={styles.container}>
            <OutletInfo />
        </div>
    );
};
