import axios from "@axios";
import React from "react";
import { notification } from "antd";

export const useProductLine = () => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const getProductLineList = React.useCallback(async (filter) => {
        try {
            setLoading(true);
            const res = await axios.get("/product-line", { params: { ...filter } });
            return res.data;
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    const createProductLine = React.useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await axios.post("/product-line", data);
            notification.success({ message: res.statusText });
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    const updateProductLine = React.useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await axios.put("/product-line", data);
            notification.success({ message: res.statusText });
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    const deleteProductLine = React.useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await axios.delete("/product-line", { data: data });
            notification.success({ message: res.statusText });
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    const importFile = React.useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await axios.post("/product-line/import", data);

            console.log(res);
            if (res.status === 206) {
                notification.warning({ message: res.statusText });
            } else {
                notification.success({ message: res.statusText });
            }
            return res.data;
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    return {
        loading,
        getProductLineList,
        createProductLine,
        updateProductLine,
        deleteProductLine,
        importFile
    };
};
