import React from "react";
import { Typography, Spin } from "antd";
import styles from "./styles.module.scss";
import { DefaulSelect } from "@components/Select";
import { Card, Row, Form, Col } from "antd";
import { IRegion, IUser } from "@pages/Outlet/ts/iterfaces";
import { CButton } from "@components/Button";
import { TypeOutlet } from "@components/TypeOutlet";
import { useProfile } from "@hooks/useContext";
import { CInput } from "@components/Input";
import { REQUIRED } from "@utils/validation";
import { useState } from "react";
import { useUser } from "@api/user";
import { useEffect } from "react";
import { useOutlet } from "@api/outlet";
import { useRegion } from "@api/region";
import { useLocation } from "react-router-dom";
import { useNotification } from "@hooks/UI/useNotification";
import Maps from "@pages/YandexMap";
import { IOutlet } from "./ts/interface";
import { useHistory } from "react-router-dom";

export const EditOutlet = () => {
    const locationstate = useLocation().state as { record: IOutlet };
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { UseDefaultNotification } = useNotification();
    const { getUserList } = useUser();
    const { getRegionList } = useRegion();
    const { user } = useProfile();
    const [city, setCity] = useState<string>(locationstate.record.city);
    const [address, setAddress] = useState<string>(locationstate.record.address);
    const { updateOutlet } = useOutlet();
    const [specialization, setSpecialization] = useState<String>("");
    const [isCoordinatesError, setIsCoordinatesError] = useState<boolean>(true);
    const [typeOutlet, settypeOutlet] = useState<String>("");
    const [users, setUsers] = useState<IUser[]>([]);
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [isButtonDisable, setDisableButton] = useState<boolean>(true);

    const getTypeId = (regions: { id: number; name: string }[] | null, value: number) => {
        if (regions?.length) {
            for (const type of regions) {
                if (type.id === value) {
                    return type.id;
                }
            }
        }
    };

    const setCoordinatesError = () => {
        UseDefaultNotification("error", {
            message: "Ошибка",
            description: "Не удалось найти адрес на карте! Проверьте корректность адреса",
            duration: 3
        });
    };

    const getPersonId = (users: { id: number; fullName: string }[] | null, value: number) => {
        if (users?.length) {
            for (const type of users) {
                if (type.id === value) {
                    return type.id;
                }
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        const getUsersList = async () => {
            const userList = await getUserList({});
            setUsers(userList.filter((e: any) => e.id !== user?.id));
        };
        const getRegionsList = async () => {
            const regionList = await getRegionList();
            setRegions(regionList);
        };

        getRegionsList().catch((e) => console.error(e));
        getUsersList().catch((e) => console.error(e));
    }, [getUserList, getRegionList, user?.id]);

    const selectChangeHandler = (value: any) => {
        form.setFieldsValue({ regionId: getTypeId(regions, value) });
        setDisableButton(false);
    };

    const selectHandler = (value: any) => {
        form.setFieldsValue({ people: getPersonId(users, value) });
        setDisableButton(false);
    };
    const typesRegionsOption = React.useMemo(() => {
        return regions?.map((item) => ({ id: item.id, label: item.name }));
    }, [regions]);

    const typesPeopleOption = React.useMemo(() => {
        return users?.map((item) => ({ id: item.id, label: item.fullName }));
    }, [users]);

    const onBackClickhandler = () => {
        history.goBack();
    };
    const onFinishForm = async (value: any) => {
        setDisableButton(true);
        const user = users.find((item) => item.id === value["userId"]);
        value.user = user;
        const formData = new FormData();
        value["id"] = locationstate.record.id;
        value["specialization"] = specialization;
        value["type"] = typeOutlet;
        for (let key in value) {
            if (value[key] === undefined || value[key] === "" ) {
                value[key] = null;
            }
            formData.append(key, value[key]);
        }
        await updateOutlet(formData);
        setDisableButton(true);
        history.goBack();
    };
    const checkNullOrUndefined = (value: string) => {
        if (value === "undefined" || value === "null") {
            return "";
        }
        return value;
    };

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                onFinish={onFinishForm}
                initialValues={{
                    legalName: checkNullOrUndefined(locationstate.record.legalName),
                    taxNumber: locationstate.record.taxNumber,
                    userId: locationstate.record.user.id,
                    name: checkNullOrUndefined(locationstate.record.name),
                    address: checkNullOrUndefined(locationstate.record.address),
                    regionId: locationstate.record.region.id,
                    city: checkNullOrUndefined(locationstate.record.city),
                    site: checkNullOrUndefined(locationstate.record.site),
                    comment: checkNullOrUndefined(locationstate.record.comment),
                    type: typeOutlet,
                    specialization: specialization
                }}
            >
                <Card className={styles.card}>
                    <Typography.Title style={{ marginBottom: "24px" }} level={3}>
                        Клиент
                    </Typography.Title>

                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <Form.Item name="legalName" rules={[REQUIRED]}>
                                <CInput
                                    type="text"
                                    name="legalNameInput"
                                    autoComplete="off"
                                    label="Юр. наименование"
                                    onChange={() => setDisableButton(false)}
                                />
                            </Form.Item>
                        </Col>
                        <Col className={styles.form_field}>
                            <Form.Item
                                name="taxNumber"
                                rules={[
                                    { required: true, message: "Поле обязательно" },
                                    {
                                        pattern: new RegExp(/^(\d{10}|\d{12})$/),
                                        message: "Поле должно содержать либо 10 цифр либо 12"
                                    }
                                ]}
                            >
                                <CInput
                                    type="text"
                                    name="tinInput"
                                    label="ИНН"
                                    autoComplete="off"
                                    onChange={() => setDisableButton(false)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card className={styles.card}>
                    <Typography.Title style={{ marginBottom: "24px" }} level={3}>
                        Торговая точка
                    </Typography.Title>
                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <Form.Item name="userId" rules={[REQUIRED]}>
                                <DefaulSelect
                                    options={typesPeopleOption}
                                    label="Выберите торгового представителя"
                                    onChange={selectHandler}
                                    className={styles.default_select}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="name" style={{ marginBottom: "48px" }} rules={[REQUIRED]}>
                        <CInput
                            name="nameInput"
                            label="Название магазина"
                            autoComplete="off"
                            onChange={() => setDisableButton(false)}
                        />
                    </Form.Item>
                    <Form.Item name="address" style={{ marginBottom: "48px" }} rules={[REQUIRED]}>
                        <CInput
                            name="addressInput"
                            label="Адрес"
                            autoComplete="off"
                            onChange={() => {
                                setDisableButton(false);
                            }}
                        />
                    </Form.Item>
                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <Form.Item name="regionId" rules={[REQUIRED]}>
                                <DefaulSelect
                                    options={typesRegionsOption}
                                    label="Регион"
                                    onChange={selectChangeHandler}
                                />
                            </Form.Item>
                        </Col>
                        <Col className={styles.form_field}>
                            <Form.Item
                                name="city"
                                style={{ marginBottom: "48px" }}
                                rules={[
                                    { required: true, message: "Поле обязательно" },
                                    {
                                        pattern: new RegExp(/^\D*$/),
                                        message: "Поле должно содержать только буквенные символы"
                                    }
                                ]}
                            >
                                <CInput
                                    name="cityInput"
                                    label="Город"
                                    autoComplete="off"
                                    onChange={() => {
                                        setDisableButton(false);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Maps
                        address={city}
                        street={address}
                        setLoading={setLoading}
                        setIsCoordinatesError={setCoordinatesError}
                    ></Maps>
                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <Form.Item name="type">
                                <Typography.Title className={styles.typography_title} level={4}>
                                    Тип точки
                                </Typography.Title>
                            </Form.Item>
                        </Col>

                        <Col className={styles.form_field}>
                            <Form.Item name="specialization">
                                <Typography.Title className={styles.typography_title} level={4}>
                                    Специализация
                                </Typography.Title>
                            </Form.Item>
                        </Col>
                    </Row>
                    <TypeOutlet
                        setButtonDisabled={setDisableButton}
                        setSpecialization={setSpecialization}
                        setTypeOutlet={settypeOutlet}
                        defaultValueSp={locationstate.record.specialization}
                        defaultValueType={locationstate.record.type}
                    />
                    <Form.Item name="site" style={{ marginBottom: "48px" }}>
                        <CInput
                            name="siteInput"
                            label="Сайт"
                            className={styles.input_site}
                            autoComplete="off"
                            onChange={() => setDisableButton(false)}
                        />
                    </Form.Item>
                    <Form.Item name="comment" style={{ marginBottom: "48px" }}>
                        <CInput
                            name="commentInput"
                            label="Комментарий"
                            autoComplete="off"
                            onChange={() => setDisableButton(false)}
                        />
                    </Form.Item>
                    <Row className={styles.form_row}>
                        <Col className={styles.form_field}>
                            <CButton
                                htmlType="submit"
                                label={`Редактировать точку`}
                                className={isButtonDisable ? styles.disabled_button : styles.submit_button}
                                disabled={isButtonDisable}
                            />
                        </Col>
                        <Col className={styles.form_field}>
                            <CButton
                                htmlType="button"
                                label={`Вернуться назад`}
                                className={styles.button_exit}
                                onClick={onBackClickhandler}
                            />
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Spin>
    );
};

export default EditOutlet;
