import { Button } from "antd";
import { ButtonProps } from "antd/es/button";
import React, { FC } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface ICustomButton {
    label: string;
    cClass?: string;
    icon?: React.ReactNode;
    background?: true | false;
}

export const CButton: FC<ICustomButton & ButtonProps> = ({ label, cClass, icon, background = false, ...rest }) => {
    return (
        <Button
            icon={icon}
            className={clsx(styles.button, cClass, background && styles.background)}
            {...rest}
        >
            {label}
        </Button>
    );
};