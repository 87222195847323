import { Redirect, Route, Switch } from "react-router-dom";
import { Products } from "./Product";
import { FeaturedProduct } from "@pages/FeaturedProduct";
import { Reports } from "./Report";
import { Users } from "./User";
import { Media } from "./Media";
import { ManageMedia } from "./Media/ManageMedia";
import { UploadPage } from "./UploadPage";
import { Maps } from "./YandexMap";
import { Outlet } from "@pages/Outlet";
import { CreateOutlet } from "./CreateOutlet";
import { Logout } from "@pages/Logout";
import { EditOutlet } from "./EditOutlet";

export const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/media" />
            </Route>

            <Route exact path="/products" component={Products} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/edit-outlet" component={EditOutlet} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/media" component={Media} />
            <Route exact path="/media/manage" component={ManageMedia} />
            <Route exact path="/outlet" component={Outlet} />
            <Route exact path="/secret/upload" component={UploadPage} />
            <Route exact path="/createOutlet" component={CreateOutlet} />
            <Route exact path="/featured-products" component={FeaturedProduct} />
        </Switch>
    );
};
