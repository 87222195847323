import React from "react";
import axios from "@axios";
import { notification } from "antd";

export const useMedia = () => {

    const [loading, setLoading] = React.useState(false);

    const getMediaList = React.useCallback(async (filter) => {
        try {
            setLoading(true);
            const res = await axios.get("/media", {
                params: {
                    ...filter
                }
            });
            return res;
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    const createMedia = React.useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await axios.post("/media", data);
            notification.success({ message: res.statusText });
            return res;
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    const updateMedia = React.useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await axios.put("/media", data);
            notification.success({ message: res.statusText });
            return res;
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    const deleteMedia = React.useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await axios.delete("/media", { data: data });
            return res;
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);

    const downloadMedia = React.useCallback(async (file) => {
        try {
            setLoading(true);
            window.open(`${process.env.REACT_APP_API_URL}/download/?file=${file}`);
        } catch {
        } finally {
            setLoading(false);
        }
    }, []);


    return { loading, getMediaList, createMedia, updateMedia, deleteMedia, downloadMedia };
};