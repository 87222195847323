import React, { FC, useState } from "react";
import { CInput } from "@components/Input";
import { CButton } from "@components/Button";
import { DefaulSelect } from "@components/Select";
import { Checkbox } from "antd";
import { Col, Form, Row, Modal } from "antd";
import styles from "./styles.module.scss";
import { IFeaturedProductModal } from "../ts/interfaces";
import { REQUIRED } from "@utils/validation";
import { useFeaturedProductLineApi } from "@api/featuredProductLine";
import { useEffect } from "react";

export const EditModal: FC<IFeaturedProductModal> = ({ fProductLine, isOpen, onClose }) => {
    const api = useFeaturedProductLineApi();
    const [form] = Form.useForm();
    const [isActive, setIsActive] = useState(fProductLine?.isActive);
    const [isCollection, setIsCollection] = useState(fProductLine?.isCollection);
    const [isButtonDisabled, setButtonDisable] = useState<boolean>(false);

    useEffect(() => {
        setIsCollection(fProductLine?.isCollection);
    }, [fProductLine]);
    React.useEffect(() => {
        if (fProductLine?.name) {
            form.setFieldsValue({
                ...fProductLine,
                isActive: fProductLine?.isActive === true ? "Активно" : "Не активно"
            });
        }
        return () => form.resetFields();
    }, [fProductLine, form]);
    const onChange = () => {
        setIsCollection(!isCollection);
    };

    const selectChangeHandler = (value: any) => {
        setIsActive(value === 1 ? true : false);
    };

    const statusOptions = React.useMemo(
        () => [
            { id: 1, label: "Активно" },
            { id: 2, label: "Не активно" }
        ],
        []
    );

    const handleSubmit = (value: any) => {
        setButtonDisable(true);
        value.isActive = isActive;
        value.isCollection = isCollection;
        value.id = fProductLine?.id;
        api.updateFeaturedProductLine(value);
        form.resetFields();
        setButtonDisable(false);
        onClose();
    };
    const onCancel = () => {
        onClose();
    };

    return (
        <>
            <Modal visible={isOpen} footer={false} onCancel={onCancel} className={styles.modal}>
                <p className={styles.text}>Редактирование фокусной линейки</p>
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item name='name' rules={[REQUIRED]}>
                        <CInput name='name' label='Название' />
                    </Form.Item>
                    <Row justify='space-between' align='middle'>
                        <Col>
                            <Form.Item name='isActive' rules={[REQUIRED]}>
                                <DefaulSelect
                                    label='Статус'
                                    className={styles.select}
                                    options={statusOptions}
                                    onChange={selectChangeHandler}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name='isCollection'>
                                <span className={styles.label}>Коллекция?</span>
                                <Checkbox
                                    className={styles.checkbox}
                                    onChange={onChange}
                                    checked={isCollection}
                                ></Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='center' align='middle' style={{ marginTop: "40px" }}>
                        <Col style={{ marginRight: "20px" }}>
                            <CButton
                                label='Вернуться'
                                className={
                                    isButtonDisabled
                                        ? styles.button__cancel__disabled
                                        : styles.button__cancel__active
                                }
                                onClick={onClose}
                                disabled={isButtonDisabled}
                            />
                        </Col>
                        <Col>
                            <CButton
                                label='Сохранить'
                                htmlType='submit'
                                className={isButtonDisabled ? styles.button__disabled : styles.button}
                                disabled={isButtonDisabled}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default EditModal;
