import { Rule } from "antd/lib/form";

export const NOT_REQUIRED = { required: false };
export const EMAIL: Rule = { type: "email", message: "Некорректный email" };
export const REQUIRED: Rule = { required: true, message: "Поле обязательно" };
export const PREVIEW: Rule = {
    required: true,
    message: "Наобходима картинка для превью"
};
export const FILE: Rule = { required: true, message: "Добавьте файл" };
export const FULL_NAME: Rule = {
    pattern: /(?:[A-Za-zа-яА-я]+ ){1}[A-Za-zа-яА-я]+/gm,
    message: "Введите Фамилию Имя и Отчество"
};

export const NOT_REQUIRED_LESS_THAN_255 = {
    required: false,
    max: 255,
    message: "Тип оборудования не может быть больше 255 символов"
};

