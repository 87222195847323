//Для упрощения логики получения данных об IANA кодах и их русифицированных значениях, 
//было принято решение хранить собственные константы на фронте и бэке, т.к. значения статичны и не планируют меняться

import { ITimezoneItem } from "@hooks/interfaces";

export const TimeZones = {
    "Europe/Kaliningrad": "Калининград - UTC+2",
    "Europe/Moscow": "Москва - UTC+3",
    "Europe/Samara": "Самара - UTC+4",
    "Asia/Yekaterinburg": "Екатеринбург - UTC+5",
    "Asia/Omsk": "Омск - UTC+6",
    "Asia/Novosibirsk": "Новосибирск - UTC+7",
    "Asia/Irkutsk": "Иркутск - UTC+8",
    "Asia/Yakutsk": "Якутск - UTC+9",
    "Asia/Vladivostok": "Владивосток - UTC+10",
    "Asia/Magadan": "Магадан - UTC+11",
    "Asia/Kamchatka": "Камчатка - UTC+12",
}

export const generateTimezonesArray = () => {
    const array: ITimezoneItem[] = [];
    let index = 0;
    for (const timezoneKey in TimeZones) {
        const timezoneItem: ITimezoneItem = {
            id: index++,
            label: TimeZones[timezoneKey as keyof typeof TimeZones],
            ianaCode: timezoneKey,
        }
        array.push(timezoneItem);
    }
    return array;
}