import React from "react";
import styles from "./styles.module.scss";

export const ApplicationDownload = () => {
    return (
        <div className={styles.download_container}>
            <div className={styles.link_container}>
                <div className={styles.download_link}>
                    <a
                        href={`itms-services://?action=download-manifest&url=${process.env.REACT_APP_PUBLIC_PATH}/application/plistfile.plist`}
                    >
                        Скачать приложение
                    </a>
                </div>
            </div>
        </div>
    );
};
