import { YMaps, Map, SearchControl } from "@pbe/react-yandex-maps";
import { useState, useRef } from "react";
import styles from "./styles.module.scss";

interface IMapsParams {
    address: string;
    street: string;
    setIsCoordinatesError: () => void;
    setLoading: (value: boolean) => void;
}

export const Maps = (params: IMapsParams) => {
    const ymapsRef = useRef<any>(null);
    const placemarkRef = useRef<any>(null);
    const [coordinates, setCoordinates] = useState<any | null>(null);
    const mapRef = useRef<any>(null);

    const CreateDefaultParametres = () => {
        if (
            params.address === "" ||
            params.address === "undefined" ||
            params.address === "null" ||
            params.address === undefined ||
            params.address === null ||
            params.street === "" ||
            params.street === "undefined" ||
            params.street === "null" ||
            params.street === undefined ||
            params.street === null
        ) {
            params.setLoading(false);
            params.setIsCoordinatesError();
            return;
        }

        const AddressValue = params.address!.concat(",", " ", params.street!);
        if (!AddressValue) {
            mapRef.current.setCenter([57.75, 37.61]);
            return;
        }
        ymapsRef.current.geocode(AddressValue).then((result: any) => {
            const FirstResult = result.geoObjects.get(0);
            if (result) {
                switch (FirstResult.properties.get("metaDataProperty.GeocoderMetaData.precision")) {
                    case "exact":
                        const coordinates = (FirstResult as any).geometry.getCoordinates();
                        setCoordinates(coordinates);
                        mapRef.current.geoObjects.remove(placemarkRef.current);
                        placemarkRef.current = createPlacemark(coordinates);
                        mapRef.current.geoObjects.add(placemarkRef.current);
                        mapRef.current.setBounds(mapRef.current.geoObjects.getBounds());
                        mapRef.current.setZoom(10);
                        params.setLoading(false);
                        break;
                    case "number":
                    case "near":
                    case "range":
                        params.setLoading(false);
                        params.setIsCoordinatesError();
                        break;

                    case "street":
                        params.setLoading(false);
                        params.setIsCoordinatesError();

                        break;
                    case "other":
                    default:
                        params.setLoading(false);
                        params.setIsCoordinatesError();
                        break;
                }
            } else {
                params.setLoading(false);
                params.setIsCoordinatesError();
                return;
            }
        });
    };
    const createPlacemark = (coordinates: any): any => {
        return new ymapsRef!.current.Placemark(
            coordinates,

            {
                preset: "islands#violetDotIconWithCaption",
                draggable: true
            }
        );
    };
    const CreateMap = () => {
        return (
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
                <YMaps
                    query={{ load: "package.full", apikey: `${process.env.REACT_APP_YANDEX_MAP_API_KEY}` }}
                >
                    <Map
                        state={{
                            controls: [],
                            center: coordinates === null ? [55.7522, 37.6156] : coordinates,
                            zoom: 9
                        }}
                        instanceRef={mapRef}
                        onLoad={(ymap) => {
                            ymapsRef.current = ymap;
                            CreateDefaultParametres();
                        }}
                        className={styles.map}
                        options={{ suppressMapOpenBlock: true }}
                    >
                        <SearchControl
                            options={{ float: "left" }}
                            onClear={() => {
                                mapRef.current.setBounds(mapRef.current.geoObjects.getBounds());
                                mapRef.current.setZoom(10);
                            }}
                        />
                    </Map>
                </YMaps>
            </div>
        );
    };
    return <>{CreateMap()}</>;
};

export default Maps;
