import React, { useCallback, useState } from "react";
import { Modal } from "antd";

const defaultCancelText = "Отмена";

export const useConfirmationDialog
    = (
    headerText: string,
    bodyText: string,
    confirmationButtonText: string,
    onConfirmClick: () => void
) => {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        setIsOpen(true);
    };

    const Dialog = useCallback(() => {
        const handleConfirmClick = () => {
            setIsOpen(false);
            onConfirmClick();
        };

        return <Modal
            title={headerText}
            visible={isOpen}
            onOk={handleConfirmClick}
            okText={confirmationButtonText}
            cancelText={defaultCancelText}
            onCancel={() => setIsOpen(false)}
        >
            {bodyText}
        </Modal>;
    }, [isOpen, headerText, confirmationButtonText, bodyText, onConfirmClick]);

    return {
        Dialog,
        onOpen
    };
};